import { useContext } from 'react'
import { FetchResult } from '@apollo/client'

import {
  CustomerAddressCreateMutation,
  CustomerAddressCreateMutationVariables,
  CustomerAddressDeleteMutation,
  CustomerAddressDeleteMutationVariables,
  CustomerAddressUpdateMutation,
  CustomerAddressUpdateMutationVariables,
  CustomerDefaultAddressUpdateMutation,
  CustomerDefaultAddressUpdateMutationVariables,
  MailingAddressFragmentFragment,
} from '@data/shopify/storefront/types'
import {
  CUSTOMER_ADDRESS_CREATE,
  CUSTOMER_ADDRESS_DELETE,
  CUSTOMER_ADDRESS_UPDATE,
  CUSTOMER_DEFAULT_ADDRESS_UPDATE,
} from '@data/shopify/storefront/mutations/customer'
import { useUser } from '@lib/auth'
import { AddressFormValues, UserAddress } from '@lib/user'
import { ShopContext } from '@lib/shop'
import { parseMailingAddressValues } from '../customer'
import { parseShopifyGlobalId } from '../client'
import {
  parseMutationResult,
  ParseResults,
  ParseStatus,
  ShopifyClient,
} from './client'

/**
 * Gets address creation validation results.
 */
const parseCustomerAddressCreateResult = (
  customerAddressCreateResult: FetchResult<CustomerAddressCreateMutation>
): ParseResults => {
  return parseMutationResult(
    customerAddressCreateResult.data?.customerAddressCreate
      ?.customerUserErrors ?? []
  )
}

/**
 * Gets address updating validation results.
 */
const parseCustomerAddressUpdateResult = (
  customerAddressUpdateResult: FetchResult<CustomerAddressUpdateMutation>
): ParseResults => {
  return parseMutationResult(
    customerAddressUpdateResult.data?.customerAddressUpdate
      ?.customerUserErrors ?? []
  )
}

/**
 * Gets address deletion validation results.
 */
const parseCustomerAddressDeleteResult = (
  customerAddressDeleteResult: FetchResult<CustomerAddressDeleteMutation>
): ParseResults => {
  return parseMutationResult(
    customerAddressDeleteResult.data?.customerAddressDelete
      ?.customerUserErrors ?? []
  )
}

/**
 * Gets address setting as default validation results.
 */
const parseCustomerDefaultAddressUpdateResult = (
  customerDefaultAddressUpdateResult: FetchResult<CustomerDefaultAddressUpdateMutation>
): ParseResults => {
  return parseMutationResult(
    customerDefaultAddressUpdateResult.data?.customerDefaultAddressUpdate
      ?.customerUserErrors ?? []
  )
}

/**
 * Updates user's default address setting.
 */
const setAddressAsDefault = async (
  addressId: string,
  token: string,
  shopifyStorefrontClient: ShopifyClient
) => {
  // Set the new address as the default
  const customerDefaultAddressUpdateResult =
    await shopifyStorefrontClient.mutate<
      CustomerDefaultAddressUpdateMutation,
      CustomerDefaultAddressUpdateMutationVariables
    >({
      mutation: CUSTOMER_DEFAULT_ADDRESS_UPDATE,
      variables: {
        customerAccessToken: token,
        addressId,
      },
    })

  return parseCustomerDefaultAddressUpdateResult(
    customerDefaultAddressUpdateResult
  )
}

/**
 * Returns a method that creates a new user address.
 */
export const useCreateAddress = () => {
  const { user, mutateUser } = useUser()
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return async ({ isDefault, ...values }: AddressFormValues, token: string) => {
    try {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      const customerAddressCreateResult = await shopifyStorefrontClient.mutate<
        CustomerAddressCreateMutation,
        CustomerAddressCreateMutationVariables
      >({
        mutation: CUSTOMER_ADDRESS_CREATE,
        variables: {
          customerAccessToken: token,
          address: values,
        },
      })

      const createAddressResult = parseCustomerAddressCreateResult(
        customerAddressCreateResult
      )
      const address =
        customerAddressCreateResult.data?.customerAddressCreate?.customerAddress

      if (address) {
        const addressFragment = address as MailingAddressFragmentFragment
        const addressFormValues = parseMailingAddressValues(addressFragment)

        if (isDefault) {
          // Set the new address as the default
          await setAddressAsDefault(
            addressFragment.id,
            token,
            shopifyStorefrontClient
          )
        }

        // Mutate user with the new address list
        const addresses =
          user?.addresses?.map((address) => ({
            ...address,
            isDefault: !isDefault && !!address.isDefault,
          })) ?? []
        addresses.push({
          id: addressFragment.id,
          globalId: parseShopifyGlobalId(addressFragment.id),
          formatted: addressFragment.formatted,
          isDefault: !!isDefault,
          values: addressFormValues,
        })
        mutateUser({
          ...user,
          isLoggedIn: !!user?.isLoggedIn,
          addresses,
        })
      }

      return createAddressResult
    } catch (error) {
      console.log(error)

      return {
        fieldErrors: {},
        errorCount: 0,
        status: ParseStatus.UNKNOWN_ERROR,
      }
    }
  }
}

/**
 * Gets new default address.
 */
const getNewDefaultAddress = (
  addresses: UserAddress[],
  isDefault: boolean,
  globalId?: number
) => {
  const otherAddresses = addresses.filter(
    (address) => address.globalId !== globalId
  )

  if (isDefault || otherAddresses.length === 0) {
    return addresses.find((address) => address.globalId === globalId)
  }

  return (
    otherAddresses.find((address) => address.isDefault) ?? otherAddresses[0]
  )
}

/**
 * Returns a method that updates a user address.
 */
export const useUpdateAddress = () => {
  const { user, mutateUser } = useUser()
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return async (
    addressId: string,
    { isDefault, ...values }: AddressFormValues,
    token: string
  ) => {
    try {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      const addressGlobalId = parseShopifyGlobalId(addressId)
      const customerAddressUpdateResult = await shopifyStorefrontClient.mutate<
        CustomerAddressUpdateMutation,
        CustomerAddressUpdateMutationVariables
      >({
        mutation: CUSTOMER_ADDRESS_UPDATE,
        variables: {
          customerAccessToken: token,
          id: addressId,
          address: values,
        },
      })

      const updateAddressResult = parseCustomerAddressUpdateResult(
        customerAddressUpdateResult
      )
      const address =
        customerAddressUpdateResult.data?.customerAddressUpdate?.customerAddress

      if (address) {
        const addressFragment = address as MailingAddressFragmentFragment
        const userAddresses = user?.addresses ?? []
        const currentDefaultUserAddress = userAddresses.find(
          (userAddress) => userAddress.isDefault
        )
        const newDefaultUserAddress = getNewDefaultAddress(
          userAddresses,
          isDefault,
          addressGlobalId
        )
        const addressFormValues = parseMailingAddressValues(addressFragment)

        if (
          newDefaultUserAddress &&
          newDefaultUserAddress.globalId !== currentDefaultUserAddress?.globalId
        ) {
          // Set new default address
          await setAddressAsDefault(
            newDefaultUserAddress.id,
            token,
            shopifyStorefrontClient
          )
        }

        // Mutate user with the new address list
        const addresses =
          user?.addresses?.map((userAddress) => {
            if (userAddress.globalId === addressGlobalId) {
              return {
                id: addressId,
                globalId: addressGlobalId,
                formatted: addressFragment.formatted,
                isDefault:
                  userAddress.globalId === newDefaultUserAddress?.globalId,
                values: addressFormValues,
              }
            }

            return {
              ...userAddress,
              isDefault:
                userAddress.globalId === newDefaultUserAddress?.globalId,
            }
          }) ?? []
        mutateUser({
          ...user,
          isLoggedIn: !!user?.isLoggedIn,
          addresses,
        })
      }

      return updateAddressResult
    } catch (error) {
      console.log(error)

      return {
        fieldErrors: {},
        errorCount: 0,
        status: ParseStatus.UNKNOWN_ERROR,
      }
    }
  }
}

/**
 * Returns a method that deletes a user address.
 */
export const useDeleteAddress = () => {
  const { user, mutateUser } = useUser()
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return async (addressId: string, token: string) => {
    try {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      const addressGlobalId = parseShopifyGlobalId(addressId)
      const customerAddressDeleteResult = await shopifyStorefrontClient.mutate<
        CustomerAddressDeleteMutation,
        CustomerAddressDeleteMutationVariables
      >({
        mutation: CUSTOMER_ADDRESS_DELETE,
        variables: {
          customerAccessToken: token,
          id: addressId,
        },
      })

      const deleteAddressResult = parseCustomerAddressDeleteResult(
        customerAddressDeleteResult
      )

      if (
        deleteAddressResult.status === ParseStatus.OK &&
        deleteAddressResult.errorCount === 0
      ) {
        // Mutate user with the new address list
        const addresses =
          user?.addresses?.filter(
            (address) => address.globalId !== addressGlobalId
          ) ?? []
        mutateUser({
          ...user,
          isLoggedIn: !!user?.isLoggedIn,
          addresses,
        })
      }

      return deleteAddressResult
    } catch (error) {
      console.log(error)

      return {
        fieldErrors: {},
        errorCount: 0,
        status: ParseStatus.UNKNOWN_ERROR,
      }
    }
  }
}
