import { useCallback, useContext, useEffect, useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import {
  OrderFinancialStatus,
  OrderFulfillmentStatus,
} from '@data/shopify/storefront/types'
import {
  HasAccountOrderListStrings,
  SanityAccountOrderListStrings,
} from '@data/sanity/queries/types/strings'
import { getShopifyUserOrders } from '@lib/shopify/graphql/customer'
import {
  getCustomerHasMoreOrders,
  getCustomerOrderCursor,
  parseCustomerOrders,
} from '@lib/shopify/customer'
import { useUser } from '@lib/auth'
import { useGetFormattedPrice } from '@lib/helpers'
import { LanguageContext } from '@lib/language'
import { serializers } from '@lib/serializers'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'
import { UserOrder } from '@lib/user'

import Button, { ButtonSize, ButtonVariant } from '@components/buttons/button'

interface AccountOrderListProps extends HasAccountOrderListStrings {
  className?: string
}

type PaymentStatusValues<T> = {
  [P in OrderFinancialStatus]: T
}

type FulfillmentStatusValues<T> = {
  [P in OrderFulfillmentStatus]: T
}

/**
 * User order list hook.
 */
const useUserOrderList = () => {
  const { shopifyStorefrontClient } = useContext(ShopContext)
  const { user } = useUser()
  const [orders, setOrders] = useState<UserOrder[]>([])
  const [orderCursor, setOrderCursor] = useState<string | null>(null)
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const clearOrders = () => {
    setOrders([])
    setOrderCursor(null)
    setHasMoreOrders(false)
    setIsInitialized(false)
  }

  // Read more orders
  const loadMoreOrders = useCallback(async () => {
    if (!user?.token) {
      return
    }

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoadingOrders(true)

    const shopifyUserOrders = await getShopifyUserOrders(
      shopifyStorefrontClient,
      user.token,
      orderCursor
    )
    setOrders((orders) => [
      ...(orders ?? []),
      ...parseCustomerOrders(shopifyUserOrders),
    ])
    setOrderCursor(getCustomerOrderCursor(shopifyUserOrders) ?? null)
    setHasMoreOrders(getCustomerHasMoreOrders(shopifyUserOrders) ?? false)

    setIsLoadingOrders(false)
  }, [orderCursor, shopifyStorefrontClient, user?.token])

  // Initialize order list
  useEffect(() => {
    if (!isInitialized && user?.token) {
      // Load first page
      loadMoreOrders()
      setIsInitialized(true)
    }

    if (isInitialized && !user?.token) {
      clearOrders()
    }
  }, [isInitialized, loadMoreOrders, user?.token])

  return [orders, hasMoreOrders, isLoadingOrders, loadMoreOrders] as const
}

/**
 * Gets order payment status texts.
 */
const getPaymentStatuses = (
  accountStrings: SanityAccountOrderListStrings
): PaymentStatusValues<string> => ({
  [OrderFinancialStatus.Authorized]:
    accountStrings.accountOrderListPaymentStatusAuthorized,
  [OrderFinancialStatus.Paid]: accountStrings.accountOrderListPaymentStatusPaid,
  [OrderFinancialStatus.PartiallyPaid]:
    accountStrings.accountOrderListPaymentStatusPartiallyPaid,
  [OrderFinancialStatus.PartiallyRefunded]:
    accountStrings.accountOrderListPaymentStatusPartiallyRefunded,
  [OrderFinancialStatus.Pending]:
    accountStrings.accountOrderListPaymentStatusPending,
  [OrderFinancialStatus.Refunded]:
    accountStrings.accountOrderListPaymentStatusRefunded,
  [OrderFinancialStatus.Voided]:
    accountStrings.accountOrderListPaymentStatusVoided,
})

/**
 * Gets order fulfillment status texts.
 */
const getFulfillmentStatuses = (
  accountStrings: SanityAccountOrderListStrings
): FulfillmentStatusValues<string> => ({
  [OrderFulfillmentStatus.Fulfilled]:
    accountStrings.accountOrderListFulfillmentStatusFulfilled,
  [OrderFulfillmentStatus.InProgress]:
    accountStrings.accountOrderListFulfillmentStatusInProgress,
  [OrderFulfillmentStatus.OnHold]:
    accountStrings.accountOrderListFulfillmentStatusOnHold,
  [OrderFulfillmentStatus.Open]:
    accountStrings.accountOrderListFulfillmentStatusOpen,
  [OrderFulfillmentStatus.PartiallyFulfilled]:
    accountStrings.accountOrderListFulfillmentStatusPartiallyFulfilled,
  [OrderFulfillmentStatus.PendingFulfillment]:
    accountStrings.accountOrderListFulfillmentStatusPendingFulfillment,
  [OrderFulfillmentStatus.Restocked]:
    accountStrings.accountOrderListFulfillmentStatusRestocked,
  [OrderFulfillmentStatus.Scheduled]:
    accountStrings.accountOrderListFulfillmentStatusScheduled,
  [OrderFulfillmentStatus.Unfulfilled]:
    accountStrings.accountOrderListFulfillmentStatusUnfulfilled,
})

const AccountOrderList = ({
  accountStrings,
  className,
}: AccountOrderListProps) => {
  const getFormattedPrice = useGetFormattedPrice()
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const [orders, hasMore, isLoading, loadMore] = useUserOrderList()

  const paymentStatuses = getPaymentStatuses(accountStrings)
  const fulfillmentStatuses = getFulfillmentStatuses(accountStrings)

  const dateFormat = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <div className={className}>
      <h4 className="font-semibold">
        {accountStrings.accountOrderListHeading}
      </h4>

      {orders.length === 0 && !isLoading && (
        <BlockContent
          renderContainerOnSingleChild
          className="rc mt-3"
          blocks={accountStrings.accountOrderListEmpty}
          serializers={serializers}
        />
      )}

      {orders.length > 0 && (
        <div className="mt-4">
          <div className="justify-evenly py-4 px-5 text-sm hidden sm:flex">
            <div className="w-[20%] pr-3">
              {accountStrings.accountOrderListOrder}
            </div>
            <div className="w-[20%] pr-3">
              {accountStrings.accountOrderListDate}
            </div>
            <div className="w-[20%] pr-3">
              {accountStrings.accountOrderListPaymentStatus}
            </div>
            <div className="w-[20%] pr-3">
              {accountStrings.accountOrderListFulfillmentStatus}
            </div>
            <div className="w-[20%] text-right">
              {accountStrings.accountOrderListTotal}
            </div>
          </div>

          {orders.map((order) => (
            <a
              key={order.id}
              className="flex flex-col sm:flex-row cursor-pointer py-5 px-5 border-t border-gray border-opacity-50 text-sm bg-gray-light bg-opacity-30 hover:bg-opacity-50"
              href={order.url}
              target="_blank"
              rel="noreferrer"
            >
              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListOrder}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {order.id}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListDate}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {dateFormat.format(new Date(order.date))}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListPaymentStatus}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {order.paymentStatus
                    ? paymentStatuses[order.paymentStatus]
                    : '-'}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListFulfillmentStatus}
                </div>

                <div className="text-right sm:text-left sm:pr-3">
                  {fulfillmentStatuses[order.fulfillmentStatus]}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountOrderListTotal}
                </div>

                <div className="text-right">
                  {getFormattedPrice(order.total * 100)}
                </div>
              </div>
            </a>
          ))}

          {hasMore && (
            <div className="mt-6 text-center">
              <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
                disabled={isLoading}
                onClick={loadMore}
                className="min-w-[140px]"
              >
                {isLoading ? strings.buttonLoading : strings.buttonLoadMore}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AccountOrderList
