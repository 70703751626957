import cx from 'classnames'

import { useGetFormattedPrice } from '@lib/helpers'

export interface BundlePriceDiscount {
  amount: number
  percent: number
}

interface BundlePriceProps {
  price: number
  discount: BundlePriceDiscount
}

const BundlePrice = ({ price, discount }: BundlePriceProps) => {
  const getFormattedPrice = useGetFormattedPrice()

  return (
    <div className="uppercase flex items-center py-4 mb-2">
      <p
        className={cx('mr-3 text-lg', {
          'line-through': discount.amount > 0,
        })}
      >
        {getFormattedPrice(price)}
      </p>

      {discount.amount > 0 && (
        <>
          <p className="mr-3 text-lg">
            {getFormattedPrice(price - discount.amount)}
          </p>

          <p className="bg-pageText text-pageBG text-sm py-1 px-2 rounded-sm">
            -{discount.percent.toFixed(0)}%
          </p>
        </>
      )}
    </div>
  )
}

export default BundlePrice
