import { SanityProductBundleSlotProduct } from '@data/sanity/queries/types/product'

import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'

interface BundleSlotSelectionProps {
  label?: string
  products: SanityProductBundleSlotProduct[]
  selectedProductId: number
  onClick(product: SanityProductBundleSlotProduct): void
}

const BundleSlotSelection = ({
  label,
  products,
  selectedProductId,
  onClick,
}: BundleSlotSelectionProps) => (
  <div className="flex items-center justify-between mb-4">
    {label && (
      <p className="mr-12 text-base font-semibold uppercase tracking-wider">
        {label}
      </p>
    )}

    <div className="flex flex-wrap items-center justify-end -mt-2">
      {products.map((product) => (
        <Button
          variant={ButtonVariant.SECONDARY}
          color={ButtonColor.BLACK}
          size={ButtonSize.SMALL}
          key={product.id}
          isActive={selectedProductId == product.id}
          className="mt-2 ml-2"
          onClick={() => onClick(product)}
        >
          {product.title}
        </Button>
      ))}
    </div>
  </div>
)

export default BundleSlotSelection
