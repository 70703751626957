import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  SanityPrintType,
  SanityPrintShopType,
} from '@data/sanity/queries/types/shop'
import { copyToClipboard } from '@lib/helpers'
import { getPrintAssetSections } from '@lib/print'
import { getPageUrl, PageType } from '@lib/routes'
import { SchoolContext } from '@lib/school'
import { ShopFormContext } from '@lib/shop'
import { SiteContext } from '@lib/site'
import { ShopFormStringsContext } from '@lib/strings'

import Icon from '@components/icon'
import ShopFormPrintReviewSection from './print-review-section'
import { SanityShopFormStrings } from '@data/sanity/queries/types/strings'
import Alert from '@components/alert'

interface ShopFormStep3Props {
  isStepSeen: boolean
}

/**
 * Gets the title of an review section.
 */
const getReviewSectionTitle = (
  shopFormStrings: SanityShopFormStrings,
  type: SanityPrintType,
  isOptimized: boolean,
  index: number
) => {
  const sectionTitleMap = {
    [SanityPrintType.LOGO]: {
      '0': shopFormStrings.shopFormLogoReviewSectionTitle,
      '1': shopFormStrings.shopFormOptimizedLogoReviewSectionTitle,
    },
    [SanityPrintType.IMAGE]: {
      '0': shopFormStrings.shopFormImageReviewSectionTitle,
      '1': shopFormStrings.shopFormOptimizedImageReviewSectionTitle,
    },
  }

  const title = sectionTitleMap[type][isOptimized ? '1' : '0'] ?? ''

  return title.replace(/{index}/gi, `${index}`)
}

/**
 * Gets the description of an review section.
 */
const getReviewSectionDescription = (
  shopFormStrings: SanityShopFormStrings,
  type: SanityPrintType,
  isOptimized: boolean
) => {
  const sectionDescriptionMap = {
    [SanityPrintType.LOGO]: {
      '0': shopFormStrings.shopFormLogoReviewSectionDescription,
      '1': shopFormStrings.shopFormOptimizedLogoReviewSectionDescription,
    },
    [SanityPrintType.IMAGE]: {
      '0': shopFormStrings.shopFormImageReviewSectionDescription,
      '1': shopFormStrings.shopFormOptimizedImageReviewSectionDescription,
    },
  }

  const description = sectionDescriptionMap[type][isOptimized ? '1' : '0'] ?? []

  return description
}

const ShopFormStep3 = ({ isStepSeen }: ShopFormStep3Props) => {
  const { settings } = useContext(SiteContext)
  const { printPermissions, shopState } = useContext(ShopFormContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)
  const { schools } = useContext(SchoolContext)

  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const shopUrl = useMemo(() => {
    if (!shopState.id || !shopState.type) {
      return ''
    }

    return `${settings?.siteURL}${getPageUrl(PageType.PRIVATE_SHOP_PAGE, [
      shopState.type,
      shopState.id,
    ])}`
  }, [settings?.siteURL, shopState.id, shopState.type])

  const printAssetSections = useMemo(
    () => getPrintAssetSections(shopState.type, printPermissions),
    [printPermissions, shopState.type]
  )

  const schoolName = useMemo(
    () =>
      schools.find((school) => school.id === shopState.schoolId)?.name ?? '',
    [schools, shopState.schoolId]
  )

  const shopTypeName = useMemo(() => {
    if (!shopState.type) {
      return ''
    }

    const shopTypeNames = {
      [SanityPrintShopType.TEAM]: shopFormStrings.shopFormTeamShop,
      [SanityPrintShopType.YEAR]: shopFormStrings.shopFormYearShop,
    }

    return shopTypeNames[shopState.type]
  }, [shopFormStrings, shopState.type])

  // Unset URL copied status after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLinkCopied) {
        setIsLinkCopied(false)
      }
    }, 3000)

    return () => clearTimeout(timer)
  }, [isLinkCopied])

  const handleCopyUrlClick = useCallback(() => {
    copyToClipboard(shopUrl)

    setIsLinkCopied(true)
  }, [shopUrl])

  return (
    <div className="max-w-[900px] mx-auto">
      <div className="">
        {!isStepSeen && (
          <Alert success className="max-w-[500px] mx-auto mb-16">
            {shopFormStrings.shopFormReadyMessage?.replace(
              /{shop_type}/gi,
              shopTypeName
            )}
          </Alert>
        )}

        <div className="text-center mb-8">
          <h1 className="mb-8">
            {shopFormStrings.shopFormUrlSectionTitle
              ?.replace(/{school_name}/gi, schoolName)
              ?.replace(/{shop_name}/gi, shopState.name ?? '')}
          </h1>
          {shopFormStrings.shopFormUrlSectionDescription && (
            <p className="mb-8">
              {shopFormStrings.shopFormUrlSectionDescription}
            </p>
          )}
        </div>

        {shopUrl && (
          <div className="flex items-center justify-center mb-14">
            <input
              className="border h-[50px] px-4 w-full max-w-[300px]"
              value={shopUrl}
              readOnly
            />
            <button
              className="bg-red text-white h-[50px] flex items-center justify-center p-2 px-4"
              disabled={isLinkCopied}
              onClick={handleCopyUrlClick}
            >
              <Icon name="Copy" id="copy-shop-url" className="text-lg mr-2" />
              <span className="">
                {isLinkCopied
                  ? shopFormStrings.shopFormUrlCopyLinkButtonCopied
                  : shopFormStrings.shopFormUrlCopyLinkButton}
              </span>
            </button>
          </div>
        )}
      </div>

      <div className="">
        <h3 className="font-semibold text-center mb-10">
          {shopFormStrings.shopFormReviewSectionTitle}
        </h3>

        {printAssetSections.map((printAssetSection, sectionIndex) => (
          <ShopFormPrintReviewSection
            key={`print-review-section-${printAssetSection.type}-${
              printAssetSection.isOptimized ? 'optimized' : 'non-optimized'
            }`}
            title={getReviewSectionTitle(
              shopFormStrings,
              printAssetSection.type,
              printAssetSection.isOptimized,
              sectionIndex + 1
            )}
            description={getReviewSectionDescription(
              shopFormStrings,
              printAssetSection.type,
              printAssetSection.isOptimized
            )}
            type={printAssetSection.type}
            slotTypes={printAssetSection.slotTypes}
            isOptimized={printAssetSection.isOptimized}
          />
        ))}
      </div>
    </div>
  )
}

export default ShopFormStep3
