import { useContext } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import { SanityContentFragment } from '@data/sanity/queries/types/content'
import { SanityPrintSlotType } from '@data/sanity/queries/types/print'
import { SanityPrintType } from '@data/sanity/queries/types/shop'
import { serializers } from '@lib/serializers'
import { ShopFormContext } from '@lib/shop'
import { ShopFormStringsContext } from '@lib/strings'

import ShopFormPrintItem, { PrintItemType } from './print-item'
import ShopFormPrintUploadArea from './print-upload-area'

interface ShopFormPrintReviewSectionProps {
  title: string
  description?: SanityContentFragment[]
  type: SanityPrintType
  slotTypes: SanityPrintSlotType[]
  isOptimized: boolean
}

const ShopFormPrintReviewSection = ({
  title,
  description,
  type,
  isOptimized,
  slotTypes,
}: ShopFormPrintReviewSectionProps) => {
  const { shopState, step, setStep } = useContext(ShopFormContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)

  const prints =
    shopState.prints?.filter(
      (print) =>
        print.slotTypes.some((slotType) => slotTypes.includes(slotType)) &&
        print.type === type &&
        !!print.isOptimized === isOptimized
    ) ?? []

  return (
    <div className="mb-10">
      <div className="mb-8">
        <h3 className="font-semibold">{title}</h3>
        {description && (
          <BlockContent
            renderContainerOnSingleChild
            className="rc mt-3"
            blocks={description}
            serializers={serializers}
          />
        )}
      </div>

      {prints.map((print) => (
        <ShopFormPrintItem
          key={print.id}
          print={print}
          type={PrintItemType.EDIT}
          onEditClick={() => setStep(2)}
        />
      ))}

      {prints.length === 0 && (
        <p className="text-center text-base opacity-30 mb-6">
          {shopFormStrings.shopFormPrintListEmpty}
        </p>
      )}

      <ShopFormPrintUploadArea
        id={`upload-area-${step}-${slotTypes.join('-')}-${type}-${
          isOptimized ? 'optimized' : 'non-optimized'
        }`}
        type={type}
        slotTypes={slotTypes}
        isOptimized={isOptimized}
      />
    </div>
  )
}

export default ShopFormPrintReviewSection
