import { useCallback, useContext, useMemo, useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import { SanityContentFragment } from '@data/sanity/queries/types/content'
import { SanityPrintSlotType } from '@data/sanity/queries/types/print'
import { SanityPrintType } from '@data/sanity/queries/types/shop'
import { LanguageContext } from '@lib/language'
import { serializers } from '@lib/serializers'
import { ShopActionType, ShopFormContext } from '@lib/shop'
import { ShopFormStringsContext } from '@lib/strings'

import ShopFormPrintItem, { PrintItemType } from './print-item'
import ShopFormPrintUploadArea from './print-upload-area'
import { removePrint } from '@lib/print'

interface ShopFormPrintUploadSectionProps {
  title: string
  description?: string | SanityContentFragment[]
  type: SanityPrintType
  slotTypes: SanityPrintSlotType[]
  isOptimized: boolean
}

const ShopFormPrintUploadSection = ({
  title,
  description,
  type,
  isOptimized,
  slotTypes,
}: ShopFormPrintUploadSectionProps) => {
  const { locale } = useContext(LanguageContext)
  const { shopState, dispatchShopState, step } = useContext(ShopFormContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)

  const [deletedPrintIds, setDeletedPrintIds] = useState<string[]>([])

  const prints = useMemo(
    () =>
      shopState.prints?.filter(
        (print) =>
          print.slotTypes.some((slotType) => slotTypes.includes(slotType)) &&
          print.type === type &&
          !!print.isOptimized === isOptimized
      ) ?? [],
    [isOptimized, shopState.prints, slotTypes, type]
  )

  const handleRemoveClick = useCallback(
    async (printId: string) => {
      if (!shopState.shopId) {
        return
      }

      // Disable print entry
      setDeletedPrintIds((currentDeletedPrintIds) => [
        ...currentDeletedPrintIds,
        printId,
      ])

      const result = await removePrint(locale, shopState.shopId, printId)

      if (!result) {
        // Enable print entry
        setDeletedPrintIds((currentDeletedPrintIds) => [
          ...currentDeletedPrintIds.filter(
            (currentDeletedPrintId) => currentDeletedPrintId !== printId
          ),
        ])
        return
      }

      // Remove print from the list
      dispatchShopState({
        action: ShopActionType.REMOVE_PRINT,
        id: printId,
      })
    },
    [dispatchShopState, locale, shopState.shopId]
  )

  return (
    <div className="border-b last:border-none pb-16 mb-16 last:mb-0">
      <div className="mb-8">
        <h3 className="font-semibold">{title}</h3>
        {description && !Array.isArray(description) && <>{description}</>}
        {description && Array.isArray(description) && (
          <BlockContent
            renderContainerOnSingleChild
            className="rc mt-3"
            blocks={description}
            serializers={serializers}
          />
        )}
      </div>

      {prints.map((print) => (
        <ShopFormPrintItem
          key={print.id}
          print={print}
          type={PrintItemType.EDIT_TITLE_AND_REMOVE}
          isDisabled={deletedPrintIds.includes(print.id)}
          onRemoveClick={handleRemoveClick}
        />
      ))}

      {prints.length === 0 && (
        <p className="text-center text-base opacity-30 mb-6">
          {shopFormStrings.shopFormPrintListEmpty}
        </p>
      )}

      <ShopFormPrintUploadArea
        id={`upload-area-${step}-${slotTypes.join('-')}-${type}-${
          isOptimized ? 'optimized' : 'non-optimized'
        }`}
        type={type}
        slotTypes={slotTypes}
        isOptimized={isOptimized}
      />
    </div>
  )
}

export default ShopFormPrintUploadSection
