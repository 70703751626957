import { useContext } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import { CartContext } from '@lib/cart'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'

interface BundleAddToCartButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  isAddToCartError: boolean
}

const BundleAddToCartButton = ({
  onClick,
  isAddToCartError,
}: BundleAddToCartButtonProps) => {
  const { cart, isCartProductAdding } = useContext(CartContext)
  const strings = useContext(StringsContext)

  return (
    <>
      <Button
        id="bundle-add-to-cart-button"
        variant={ButtonVariant.PRIMARY}
        onClick={onClick}
        disabled={!cart || isCartProductAdding || isAddToCartError}
      >
        {isCartProductAdding && strings.buttonAdding}
        {cart &&
          !isCartProductAdding &&
          !isAddToCartError &&
          strings.buttonAddToCart}
      </Button>

      {isAddToCartError && (
        <div key="error" className="mt-6">
          <Alert error>
            <BlockContent
              renderContainerOnSingleChild
              className="rc rc-error"
              blocks={strings.cartAddToCartErrorMessage}
              serializers={serializers}
            />
          </Alert>
        </div>
      )}
    </>
  )
}

export default BundleAddToCartButton
