import {
  GetCustomerOrdersQuery,
  MailingAddressFragmentFragment,
  OrderFragmentFragment,
} from '@data/shopify/storefront/types'
import { AddressFormValues, UserOrder } from '@lib/user'
import { ShopifyAddress } from './address'

interface ShopifyCustomerDefaultAddress extends ShopifyAddress {
  id: string | null
  customer_id: number
  default: boolean
}

export interface ShopifyCustomer {
  id: number
  email: string
  accepts_marketing: boolean
  created_at: string | null
  updated_at: string | null
  first_name: string
  last_name: string
  orders_count: number
  state: string
  total_spent: string
  last_order_id: string | null
  note: string | null
  verified_email: boolean
  multipass_identifier: string | null
  tax_exempt: boolean | null
  phone: string | null
  tags: string
  last_order_name: string | null
  currency: string
  accepts_marketing_updated_at: string | null
  marketing_opt_in_level: string | null
  sms_marketing_consent: string | null
  admin_graphql_api_id: string
  default_address: ShopifyCustomerDefaultAddress
}

export interface ShopifyUpdateCustomerPayload {
  customer: {
    id: number
    tax_exempt: boolean
  }
}

/**
 * Gets Shopify user subscription management URL.
 */
export const getShopifySubscriptionManagementUrl = (
  shopifyPrimaryDomain: string,
  subscriberHash: string
) =>
  `https://${shopifyPrimaryDomain}/tools/recurring/portal/${subscriberHash}/subscriptions`

/**
 * Parses Shopify customer order node.
 */
export const parseOrderNode = ({
  name,
  processedAt,
  financialStatus,
  fulfillmentStatus,
  totalPriceV2,
  statusUrl,
}: OrderFragmentFragment): UserOrder => ({
  id: name,
  date: processedAt,
  paymentStatus: financialStatus ?? null,
  fulfillmentStatus: fulfillmentStatus,
  total: totalPriceV2.amount,
  url: statusUrl,
})

/**
 * Gets user orders from Shopify order query result.
 */
export const parseCustomerOrders = ({ customer }: GetCustomerOrdersQuery) => {
  const orderNodes = (customer?.orders?.edges
    ?.map((edge) => edge.node as OrderFragmentFragment | undefined)
    ?.filter(Boolean) ?? []) as OrderFragmentFragment[]

  return orderNodes
    .sort(
      (orderNode1, orderNode2) =>
        Date.parse(orderNode2.processedAt) - Date.parse(orderNode1.processedAt)
    )
    .map(parseOrderNode)
}

/**
 * Parses Shopify mailing address into address form values.
 */
export const parseMailingAddressValues = (
  address: MailingAddressFragmentFragment,
  defaultAddressId?: string
): AddressFormValues => ({
  firstName: address.firstName ?? '',
  lastName: address.lastName ?? '',
  company: address.company ?? '',
  address1: address.address1 ?? '',
  address2: address.address2 ?? '',
  city: address.city ?? '',
  country: address.country ?? '',
  zip: address.zip ?? '',
  phone: address.phone ?? '',
  isDefault: address.id === defaultAddressId,
})

/**
 * Gets user order cursor from Shopify user query results.
 */
export const getCustomerOrderCursor = (result: GetCustomerOrdersQuery) =>
  result.customer?.orders?.edges?.map(({ cursor }) => cursor)?.pop()

/**
 * Gets whether query has more orders from Shopify user query results.
 */
export const getCustomerHasMoreOrders = (result: GetCustomerOrdersQuery) =>
  result.customer?.orders.pageInfo.hasNextPage
