import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import cx from 'classnames'

import { SanityPrintSlotType } from '@data/sanity/queries/types/print'
import {
  SanityPrintColor,
  SanityPrintType,
} from '@data/sanity/queries/types/shop'
import { getRandomString } from '@lib/helpers'
import { LanguageContext } from '@lib/language'
import { ShopFormContext, ShopFormUploadContext } from '@lib/shop'
import { ShopFormStringsContext } from '@lib/strings'

import Icon from '@components/icon'

interface ShopFormPrintUploadAreaProps {
  id: string
  type: SanityPrintType
  slotTypes: SanityPrintSlotType[]
  isOptimized: boolean
  color?: SanityPrintColor
}

interface UploadState {
  dropDepth: number
  isInDropZone: boolean
}

enum UploadActionType {
  UPDATE_DROP_DEPTH = 'updateDropDepth',
  SET_IS_IN_DROP_ZONE = 'setIsInDropZone',
}

interface UpdateDropDepthUploadAction {
  type: UploadActionType.UPDATE_DROP_DEPTH
  dropDepthDelta: number
}

interface SetIsInDropZoneUploadAction {
  type: UploadActionType.SET_IS_IN_DROP_ZONE
  isInDropZone: boolean
}

type UploadAction = UpdateDropDepthUploadAction | SetIsInDropZoneUploadAction

/**
 * Updates upload state using an upload action.
 */
const uploadStateReducer = (
  state: UploadState,
  action: UploadAction
): UploadState => {
  switch (action.type) {
    case UploadActionType.UPDATE_DROP_DEPTH: {
      const dropDepth = Math.max(0, state.dropDepth + action.dropDepthDelta)

      return {
        ...state,
        dropDepth,
        ...(dropDepth === 0 ? { isInDropZone: false } : {}),
      }
    }

    case UploadActionType.SET_IS_IN_DROP_ZONE: {
      return {
        ...state,
        isInDropZone: action.isInDropZone,
      }
    }
  }
}

const ShopFormPrintUploadArea = ({
  id,
  type,
  slotTypes,
  isOptimized,
  color,
}: ShopFormPrintUploadAreaProps) => {
  const { locale } = useContext(LanguageContext)
  const { shopState } = useContext(ShopFormContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)
  const { uploadQueue, uploadErrors, addToUploadQueue } = useContext(
    ShopFormUploadContext
  )

  const uploadAreaId = useMemo(
    () =>
      [
        type,
        slotTypes.join('-'),
        isOptimized ? 'optimized' : 'non-optimized',
        color ?? 'no-color',
      ].join(','),
    [color, isOptimized, slotTypes, type]
  )
  const isUploading = useMemo(
    () => uploadQueue.some((item) => item.uploadAreaId === uploadAreaId),
    [uploadAreaId, uploadQueue]
  )

  const [uploadState, dispatchUploadState] = useReducer(uploadStateReducer, {
    dropDepth: 0,
    isInDropZone: false,
  })

  const handleDragEnter = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    dispatchUploadState({
      type: UploadActionType.UPDATE_DROP_DEPTH,
      dropDepthDelta: 1,
    })
  }, [])

  const handleDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    dispatchUploadState({
      type: UploadActionType.UPDATE_DROP_DEPTH,
      dropDepthDelta: -1,
    })
  }, [])

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    event.dataTransfer.dropEffect = 'copy'
    dispatchUploadState({
      type: UploadActionType.SET_IS_IN_DROP_ZONE,
      isInDropZone: true,
    })
  }, [])

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      const files = event.dataTransfer.files
        ? [...event.dataTransfer.files]
        : []
      const shopId = shopState.shopId

      if (files.length > 0 && shopId) {
        // Add files to upload queue
        files.forEach((file) =>
          addToUploadQueue({
            id: getRandomString(),
            uploadAreaId,
            locale,
            shopId,
            file,
            type,
            slotTypes,
            isOptimized,
            color,
          })
        )

        event.dataTransfer.clearData()
        dispatchUploadState({
          type: UploadActionType.UPDATE_DROP_DEPTH,
          dropDepthDelta: -100,
        })
        dispatchUploadState({
          type: UploadActionType.SET_IS_IN_DROP_ZONE,
          isInDropZone: false,
        })
      }
    },
    [
      addToUploadQueue,
      color,
      isOptimized,
      locale,
      shopState.shopId,
      slotTypes,
      type,
      uploadAreaId,
    ]
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files ? [...event.target.files] : []
      const shopId = shopState.shopId

      if (files.length > 0 && shopId) {
        // Add files to upload queue
        files.forEach((file) =>
          addToUploadQueue({
            id: getRandomString(),
            uploadAreaId,
            locale,
            shopId,
            file,
            type,
            slotTypes,
            isOptimized,
            color,
          })
        )

        // Reset input value
        event.target.value = ''
      }
    },
    [
      addToUploadQueue,
      color,
      isOptimized,
      locale,
      shopState.shopId,
      slotTypes,
      type,
      uploadAreaId,
    ]
  )

  const errorMessage = uploadErrors[uploadAreaId]
  const inputId = `file-${id}`

  return (
    <div
      className={cx(
        'border border-dashed border-blue rounded-md hover:bg-blue hover:bg-opacity-5 transition-all',
        {
          'bg-blue bg-opacity-5': uploadState.isInDropZone || isUploading,
        }
      )}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      role="presentation"
    >
      <label
        className="relative flex flex-col justify-center items-center p-4 md:p-8 text-blue"
        htmlFor={inputId}
      >
        <input
          className="absolute inset-0 opacity-0 cursor-pointer "
          type="file"
          name="file"
          id={inputId}
          onChange={handleChange}
          multiple
        />

        {isUploading && (
          <span>
            {shopFormStrings.shopFormUploadingPrint?.replace(
              /{print_type}/gi,
              type
            )}
          </span>
        )}
        {!isUploading && (
          <>
            <Icon className="mb-2" name="Upload" id={inputId} />
            {!errorMessage && (
              <span>
                {shopFormStrings.shopFormUploadPrint?.replace(
                  /{print_type}/gi,
                  type
                )}
              </span>
            )}
            {!!errorMessage && <span className="text-red">{errorMessage}</span>}
          </>
        )}
      </label>
    </div>
  )
}

export default ShopFormPrintUploadArea
