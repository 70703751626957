import { useContext, useEffect, useState } from 'react'
import { FieldError, UseFormSetValue, UseFormRegister } from 'react-hook-form'

import { SanityPrintShopType } from '@data/sanity/queries/types/shop'
import { ErrorMessages } from '@lib/helpers'
import { getPageUrl, PageType } from '@lib/routes'
import { ShopFormContext } from '@lib/shop'
import { ShopFormStringsContext, StringsContext } from '@lib/strings'

import InputField from '@components/input-field'
import SimpleLink from '@components/simple-link'
import { ShopStep1FormValues } from './step-1'
import TelInputField from '@components/tel-input-field'

interface UserFormProps {
  register: UseFormRegister<ShopStep1FormValues>
  setValue: UseFormSetValue<ShopStep1FormValues>
  signupErrorMessages: ErrorMessages
  errors: {
    firstName?: FieldError
    lastName?: FieldError
    phone?: FieldError
    email?: FieldError
    password?: FieldError
  }
  className?: string
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

/**
 * User form section component.
 */
const ShopFormUserForm = ({
  register,
  setValue,
  signupErrorMessages,
  errors,
  className,
}: UserFormProps) => {
  const strings = useContext(StringsContext)
  const { shopState } = useContext(ShopFormContext)
  const { authStrings, shopFormStrings } = useContext(ShopFormStringsContext)

  const [isLoaded, setIsLoaded] = useState(false)

  // Wait for user to load
  useEffect(() => {
    if (!isLoaded && shopState.user) {
      setIsLoaded(true)
    }
  }, [isLoaded, shopState.user])

  const firstNameRegister = register('firstName', {
    validate: (firstName) => {
      if (!shopState.user?.isLoggedIn && !firstName) {
        return authStrings.signupFirstNameMissing
      }

      return true
    },
  })
  const lastNameRegister = register('lastName', {
    validate: (lastName) => {
      if (!shopState.user?.isLoggedIn && !lastName) {
        return authStrings.signupLastNameMissing
      }

      return true
    },
  })
  const emailRegister = register('email', {
    validate: (email) => {
      if (!shopState.user?.isLoggedIn && !email) {
        return authStrings.signupEmailMissing
      }

      if (!shopState.user?.isLoggedIn && !emailRegex.test(email)) {
        return strings.emailInvalid
      }

      return true
    },
  })
  const passwordRegister = register('password', {
    validate: (password) => {
      if (!shopState.user?.isLoggedIn && !password) {
        return authStrings.signupPasswordMissing
      }

      return true
    },
  })
  const phoneRegister = register('phone', {
    validate: (phone) => {
      if (!shopState.user?.isLoggedIn && !phone) {
        return authStrings.signupPhoneNumberMissing
      }

      return true
    },
  })

  if (!isLoaded || !!shopState.user?.isLoggedIn) {
    return null
  }

  return (
    <div className={className}>
      <h2 className="font-semibold text-center mb-8">
        {shopFormStrings.shopFormUserSectionTitle}
      </h2>

      {!shopState.user?.isLoggedIn && (
        <>
          <div className="flex justify-center mb-8">
            <SimpleLink
              className="text-center underline text-lg"
              href={getPageUrl(PageType.LOGIN_PAGE, null, {
                parameters: {
                  redirectUrl: getPageUrl(
                    shopState.type === SanityPrintShopType.TEAM
                      ? PageType.CREATE_TEAM_SHOP_PAGE
                      : PageType.CREATE_YEAR_SHOP_PAGE
                  ),
                },
              })}
              tabIndex={0}
            >
              {shopFormStrings.shopFormLoginLink}
            </SimpleLink>
          </div>

          <InputField
            className="mb-4"
            id="create-shop-fist-name"
            type="text"
            formRegister={firstNameRegister}
            errorMessage={
              signupErrorMessages?.firstName ?? errors.firstName?.message
            }
            label={authStrings.signupFirstName}
            placeholder={authStrings.signupFirstNamePlaceholder}
          />

          <InputField
            className="mb-4"
            id="create-shop-last-name"
            type="text"
            formRegister={lastNameRegister}
            errorMessage={
              signupErrorMessages?.lastName ?? errors.lastName?.message
            }
            label={authStrings.signupLastName}
            placeholder={authStrings.signupLastNamePlaceholder}
          />

          <TelInputField
            className="mb-4"
            id="create-shop-phone-number"
            onChange={(phone) => setValue('phone', phone.replace(/\s/g, ''))}
            errorMessage={signupErrorMessages?.phone ?? errors.phone?.message}
            label={authStrings.signupPhoneNumber}
            placeholder={authStrings.signupPhoneNumberPlaceholder}
          />

          <InputField
            className="mb-4"
            id="create-shop-email"
            type="email"
            autoComplete="email"
            formRegister={emailRegister}
            errorMessage={signupErrorMessages?.email ?? errors.email?.message}
            label={authStrings.signupEmail}
            placeholder={authStrings.signupEmailPlaceholder}
          />

          <InputField
            id="create-shop-password"
            type="password"
            autoComplete="off"
            formRegister={passwordRegister}
            errorMessage={
              signupErrorMessages?.password ?? errors.password?.message
            }
            label={authStrings.signupPassword}
            placeholder={authStrings.signupPasswordPlaceholder}
          />
        </>
      )}
    </div>
  )
}

export default ShopFormUserForm
