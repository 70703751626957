import {
  BaseSyntheticEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import BlockContent from '@sanity/block-content-to-react'

import { HasAuthStrings } from '@data/sanity/queries/types/strings'
import { ParseStatus } from '@lib/shopify/graphql/client'
import { LoginFormValues, useLoginUser } from '@lib/auth'
import { ErrorMessages, parseOptionalParameter } from '@lib/helpers'
import { getPageUrl, PageType } from '@lib/routes'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'

import Alert from '@components/alert'
import Button, { ButtonColor, ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field'

interface LoginFormProps extends HasAuthStrings {
  className?: string
}

const LoginForm = ({ authStrings, className }: LoginFormProps) => {
  const strings = useContext(StringsContext)
  const router = useRouter()
  const loginUser = useLoginUser()

  const [isError, setIsError] = useState(false)
  const [errorStatus, setErrorStatus] = useState<ParseStatus>()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<LoginFormValues>()

  const redirectUrl = useMemo(() => {
    const redirectUrl = parseOptionalParameter(router.query?.redirectUrl)

    return redirectUrl ? redirectUrl : getPageUrl(PageType.ACCOUNT_PAGE)
  }, [router.query?.redirectUrl])

  // Handle form submission
  const onSubmit = useCallback(
    async (values: LoginFormValues, event?: BaseSyntheticEvent) => {
      event?.preventDefault()

      setIsLoading(true)
      setIsError(false)

      // Login
      const loginUserResult = await loginUser(values)
      setErrorMessages(loginUserResult.fieldErrors)

      if (loginUserResult.status !== ParseStatus.OK) {
        setErrorStatus(loginUserResult.status)
        setIsError(true)
      }

      if (
        loginUserResult.status === ParseStatus.OK &&
        loginUserResult.errorCount === 0
      ) {
        reset()

        // Redirect after logging in
        router.push(redirectUrl)
      }

      setIsLoading(false)
    },
    [loginUser, redirectUrl, reset, router]
  )

  const emailRegister = register('email', {
    required: authStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })
  const passwordRegister = register('password', {
    required: authStrings.signupPasswordMissing,
  })

  const isDisabled = !!errors.email || !!errors.password

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <div key="form">
        <InputField
          id="login-email"
          type="email"
          autoComplete="email"
          formRegister={emailRegister}
          errorMessage={errorMessages?.email ?? errors.email?.message}
          label={authStrings.signupEmail}
          placeholder={authStrings.signupEmailPlaceholder}
          className="mb-4"
        />

        <InputField
          id="login-password"
          type="password"
          autoComplete="off"
          formRegister={passwordRegister}
          errorMessage={errorMessages?.password ?? errors.password?.message}
          label={authStrings.signupPassword}
          placeholder={authStrings.signupPasswordPlaceholder}
          className="mb-4"
        />

        {isError && errorStatus && (
          <div key="error" className="mt-8">
            <Alert error>
              <BlockContent
                renderContainerOnSingleChild
                className="rc rc-error"
                blocks={
                  errorStatus === ParseStatus.INVALID_CREDENTIALS
                    ? authStrings.loginFailedMessage
                    : authStrings.loginErrorMessage
                }
                serializers={serializers}
              />
            </Alert>
          </div>
        )}

        <div className="flex justify-center mt-10">
          <Button
            type="submit"
            variant={ButtonVariant.PRIMARY}
            color={ButtonColor.PINK}
            disabled={isLoading || isDisabled}
            className="min-w-[260px]"
          >
            {isLoading ? strings.buttonSubmitting : authStrings.loginSubmit}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
