import {
  Accordion,
  Accordions,
  AccountAddressDetails,
  AccountDetails,
  AccountOrderList,
  AccountProductList,
  AccountTeamShopList,
  AccountYearShopList,
  BlogPostCard,
  CollectionCarousel,
  ContentCarousel,
  CreateShopForm,
  EditShopForm,
  Freeform,
  Iframe,
  LoginForm,
  Newsletter,
  PasswordRecoveryForm,
  ProductBundleForm,
  ProductCard,
  SignupForm,
  Video,
} from '@data/sanity/schema'
import { SanityBlogPostWithoutBody, SanityBlogPostOptions } from './blog'
import { SanityContentFragment } from './content'
import { SanityImageFragment } from './image'
import { SanityProductBundle, SanityProductFragment } from './product'
import {
  HasAccountAddressDetailsStrings,
  HasAccountDetailsStrings,
  HasAccountOrderListStrings,
  HasAccountProductListStrings,
  HasAccountTeamShopListStrings,
  HasAccountYearShopListStrings,
  HasAuthStrings,
  HasShopFormStrings,
} from './strings'
import { SanityVideo } from './video'
import { Keyed } from '@lib/helpers'

export enum SanityBlockType {
  ACCORDIONS = 'accordions',
  ACCOUNT_ADDRESS_DETAILS = 'accountAddressDetails',
  ACCOUNT_DETAILS = 'accountDetails',
  ACCOUNT_ORDER_LIST = 'accountOrderList',
  ACCOUNT_PRODUCT_LIST = 'accountProductList',
  ACCOUNT_TEAM_SHOP_LIST = 'accountTeamShopList',
  ACCOUNT_YEAR_SHOP_LIST = 'accountYearShopList',
  BLOG_POST_CARD = 'blogPostCard',
  COLLECTION_CAROUSEL = 'collectionCarousel',
  CONTENT_CAROUSEL = 'contentCarousel',
  CREATE_SHOP_FORM = 'createShopForm',
  EDIT_SHOP_FORM = 'editShopForm',
  FREEFORM = 'freeform',
  IFRAME = 'iframe',
  LOGIN_FORM = 'loginForm',
  NEWSLETTER = 'newsletter',
  PASSWORD_RECOVERY_FORM = 'passwordRecoveryForm',
  PRODUCT_BUNDLE_FORM = 'productBundleForm',
  PRODUCT_CARD = 'productCard',
  SIGNUP_FORM = 'signupForm',
  VIDEO = 'video',
}

export type SanityFreeformBlock = Keyed<
  Pick<Freeform, '_type' | 'textAlign' | 'maxWidth'> & {
    content: Array<SanityContentFragment>
  }
>

export type SanityFreeform = Omit<SanityFreeformBlock, '_type' | '_key'>

export type SanityAccordionsBlockItem = Pick<Accordion, 'title'> & {
  id: string
  content: Array<SanityContentFragment>
}

export type SanityAccordionsBlock = Keyed<
  Pick<Accordions, '_type'> & {
    items: Array<SanityAccordionsBlockItem>
  }
>

export type SanityAccordions = Omit<SanityAccordionsBlock, '_type' | '_key'>

export type SanityProductCardBlock = Keyed<
  Pick<ProductCard, '_type'> & {
    product: SanityProductFragment
  }
>

export type SanityProductCard = Omit<SanityProductCardBlock, '_type' | '_key'>

export type SanitySignupFormBlock = Keyed<SignupForm & HasAuthStrings>

export type SanityLoginFormBlock = Keyed<LoginForm & HasAuthStrings>

export type SanityPasswordRecoveryFormBlock = Keyed<
  PasswordRecoveryForm & HasAuthStrings
>

export type SanityAccountDetailsBlock = Keyed<
  AccountDetails & HasAccountDetailsStrings
>

export type SanityAccountAddressDetailsBlock = Keyed<
  AccountAddressDetails & HasAccountAddressDetailsStrings
>

export type SanityAccountProductListBlock = Keyed<
  AccountProductList & HasAccountProductListStrings
>

export type SanityAccountOrderListBlock = Keyed<
  AccountOrderList & HasAccountOrderListStrings
>

export type SanityAccountTeamShopListBlock = Keyed<
  Pick<AccountTeamShopList, '_type' | 'active'> & HasAccountTeamShopListStrings
>

export type SanityAccountYearShopListBlock = Keyed<
  Pick<AccountYearShopList, '_type' | 'active'> & HasAccountYearShopListStrings
>

export type SanityVideoBlock = Keyed<Pick<Video, '_type'> & SanityVideo>

export type SanityCollectionCarouselBlock = Keyed<
  Pick<CollectionCarousel, '_type'> & {
    collection: {
      products: Array<SanityProductFragment>
    }
  }
>

export type SanityCollectionCarousel = Omit<
  SanityCollectionCarouselBlock,
  '_type' | '_key'
>

export type SanityNewsletterBlock = Keyed<
  Pick<Newsletter, '_type' | 'klaviyoListID' | 'submit'> & {
    successMsg: Array<SanityContentFragment>
    errorMsg: Array<SanityContentFragment>
    terms?: Array<SanityContentFragment>
  }
>

export type SanityNewsletter = Omit<SanityNewsletterBlock, '_type' | '_key'>

export type SanityProductBundleFormBlock = Keyed<
  Pick<ProductBundleForm, '_type' | 'showGallery'> & {
    productBundle: SanityProductBundle
  }
>

export type SanityBlogPostCardBlock = Keyed<
  Pick<BlogPostCard, '_type'> & {
    post: SanityBlogPostWithoutBody
    options?: SanityBlogPostOptions
  }
>

export enum SanityContentCarouselItemType {
  FREEFORM = 'freeform',
  PHOTO = 'photo',
  PRODUCT = 'product',
  BLOG_POST = 'blogPost',
  VIDEO = 'video',
}

type SanityContentCarouselFreeformItem = {
  _type: SanityContentCarouselItemType.FREEFORM
  freeform: SanityFreeformBlock
}

type SanityContentCarouselPhotoItem = {
  _type: SanityContentCarouselItemType.PHOTO
  photo: SanityImageFragment
}

type SanityContentCarouselProductItem = {
  _type: SanityContentCarouselItemType.PRODUCT
  product: SanityProductFragment
}

type SanityContentCarouselBlogPostItem = {
  _type: SanityContentCarouselItemType.BLOG_POST
  blogPost: SanityBlogPostWithoutBody
}

type SanityContentCarouselVideoItem = {
  _type: SanityContentCarouselItemType.VIDEO
  video: SanityVideo
}

export type SanityContentCarouselItem = Keyed<
  | SanityContentCarouselFreeformItem
  | SanityContentCarouselPhotoItem
  | SanityContentCarouselProductItem
  | SanityContentCarouselBlogPostItem
  | SanityContentCarouselVideoItem
>

export interface SanityContentCarousel {
  items: Array<SanityContentCarouselItem>
}

export type SanityContentCarouselBlock = Keyed<
  Pick<ContentCarousel, '_type'> & SanityContentCarousel
>

export type SanityCreateShopForm = Pick<CreateShopForm, 'active'> &
  HasAuthStrings &
  HasShopFormStrings

export type SanityCreateShopFormBlock = Keyed<
  Pick<CreateShopForm, '_type'> & SanityCreateShopForm
>

export type SanityEditShopForm = Pick<EditShopForm, 'active'> &
  HasAuthStrings &
  HasShopFormStrings

export type SanityEditShopFormBlock = Keyed<
  Pick<EditShopForm, '_type'> & SanityEditShopForm
>

export type SanityIframeBlock = Keyed<
  Pick<Iframe, '_type' | 'title' | 'url' | 'width' | 'height'>
>

export type SanityBlocks =
  | SanityFreeformBlock
  | SanityAccordionsBlock
  | SanityProductCardBlock
  | SanitySignupFormBlock
  | SanityLoginFormBlock
  | SanityPasswordRecoveryFormBlock
  | SanityAccountDetailsBlock
  | SanityAccountAddressDetailsBlock
  | SanityAccountProductListBlock
  | SanityAccountOrderListBlock
  | SanityAccountTeamShopListBlock
  | SanityAccountYearShopListBlock
  | SanityVideoBlock
  | SanityCollectionCarouselBlock
  | SanityNewsletterBlock
  | SanityProductBundleFormBlock
  | SanityBlogPostCardBlock
  | SanityContentCarouselBlock
  | SanityCreateShopFormBlock
  | SanityEditShopFormBlock
  | SanityIframeBlock
