import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { SanityFreeform } from '@data/sanity/queries/types/blocks'
import { serializers } from '@lib/serializers'

type FreeformProps = SanityFreeform

const Freeform = ({ maxWidth, textAlign, content }: FreeformProps) => {
  if (!content) {
    return null
  }

  return (
    <BlockContent
      renderContainerOnSingleChild
      className={cx('rc', maxWidth, textAlign)}
      blocks={content}
      serializers={serializers}
    />
  )
}

export default Freeform
