import { BaseSyntheticEvent, useContext } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import BlockContent from '@sanity/block-content-to-react'

import { HasAuthStrings } from '@data/sanity/queries/types/strings'
import { SignupFormValues, useSubmitSignupForm } from '@lib/auth'
import { getPageUrl, PageType } from '@lib/routes'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'

import Alert from '@components/alert'
import Button, { ButtonColor, ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field'
import { ParseStatus } from '@lib/shopify/graphql/client'

interface SignupFormProps extends HasAuthStrings {
  className?: string
}

const SignupForm = ({ authStrings, className }: SignupFormProps) => {
  const strings = useContext(StringsContext)
  const router = useRouter()

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<SignupFormValues>()
  const [errorStatus, isError, isLoading, errorMessages, handleSignupSubmit] =
    useSubmitSignupForm()

  const firstNameRegister = register('firstName', {
    required: authStrings.signupFirstNameMissing,
  })
  const lastNameRegister = register('lastName', {
    required: authStrings.signupLastNameMissing,
  })
  const emailRegister = register('email', {
    required: authStrings.signupEmailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })
  const passwordRegister = register('password', {
    required: authStrings.signupPasswordMissing,
  })

  const isDisabled =
    !!errors.firstName ||
    !!errors.lastName ||
    !!errors.email ||
    !!errors.password

  const onSubmit = async (
    values: SignupFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    await handleSignupSubmit(values, async () => {
      reset()

      // Redirect to account after logging in
      router.push(getPageUrl(PageType.ACCOUNT_PAGE))
    })
  }

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <InputField
        id="signup-fist-name"
        type="text"
        formRegister={firstNameRegister}
        errorMessage={errorMessages?.firstName ?? errors.firstName?.message}
        label={authStrings.signupFirstName}
        placeholder={authStrings.signupFirstNamePlaceholder}
        className="mb-4"
      />

      <InputField
        id="signup-last-name"
        type="text"
        formRegister={lastNameRegister}
        errorMessage={errorMessages?.lastName ?? errors.lastName?.message}
        label={authStrings.signupLastName}
        placeholder={authStrings.signupLastNamePlaceholder}
        className="mb-4"
      />

      <InputField
        id="signup-email"
        type="email"
        autoComplete="email"
        formRegister={emailRegister}
        errorMessage={errorMessages?.email ?? errors.email?.message}
        label={authStrings.signupEmail}
        placeholder={authStrings.signupEmailPlaceholder}
        className="mb-4"
      />

      <InputField
        id="signup-password"
        type="password"
        autoComplete="off"
        formRegister={passwordRegister}
        errorMessage={errorMessages?.password ?? errors.password?.message}
        label={authStrings.signupPassword}
        placeholder={authStrings.signupPasswordPlaceholder}
        className="mb-4"
      />

      {isError && (
        <div key="error" className="mt-8">
          <Alert error>
            <BlockContent
              renderContainerOnSingleChild
              className="rc-error"
              blocks={
                errorStatus === ParseStatus.INACTIVE_ACCOUNT
                  ? authStrings.signupAccountActivationMessage
                  : authStrings.signupErrorMessage
              }
              serializers={serializers}
            />
          </Alert>
        </div>
      )}

      <div className="flex justify-center mt-10">
        <Button
          type="submit"
          variant={ButtonVariant.PRIMARY}
          color={ButtonColor.PINK}
          disabled={isLoading || isDisabled}
          className="min-w-[260px]"
        >
          {isLoading ? strings.buttonSubmitting : authStrings.signupSubmit}
        </Button>
      </div>
    </form>
  )
}

export default SignupForm
