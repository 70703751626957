import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import cx from 'classnames'

import { SanityPrintShopType } from '@data/sanity/queries/types/shop'
import {
  HasAuthStrings,
  HasShopFormStrings,
} from '@data/sanity/queries/types/strings'
import { usePrevious } from '@lib/helpers'
import { ShopFormContext, ShopFormUploadContextProvider } from '@lib/shop'
import { ShopFormStringsContextProvider } from '@lib/strings'

import ShopFormProgressIndicator from './progress-indicator'
import ShopFormStep1 from './step-1'
import ShopFormStep2 from './step-2'
import ShopFormStep3 from './step-3'

interface ShopFormProps extends HasAuthStrings, HasShopFormStrings {
  mode: ShopFormMode
  className?: string
}

export enum ShopFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

/**
 * Gets step wrapper CSS styles.
 */
const getStepStyle = (activeStep: number): CSSProperties => ({
  flex: '0 0 100%',
  transform: `translateX(${(1 - activeStep) * 100}%)`,
  transition: 'transform .3s',
})

const ShopForm = ({
  authStrings,
  shopFormStrings,
  mode,
  className,
}: ShopFormProps) => {
  const { shopState, step } = useContext(ShopFormContext)

  const previousStep = usePrevious(step)
  const [isStep3Seen, setIsStep3Seen] = useState(mode === ShopFormMode.EDIT)

  useEffect(() => window.scrollTo(0, 0), [step])

  // Hide message in step 3 after first visit
  useEffect(() => {
    if (previousStep === 3 && step !== 3) {
      setIsStep3Seen(true)
    }
  }, [previousStep, step])

  const stepStyle = getStepStyle(step)

  const getStepClassnames = useCallback(
    (targetStep: number) =>
      cx({
        'opacity-0': step !== targetStep,
        'z-10': step === targetStep,
      }),
    [step]
  )

  return (
    <ShopFormStringsContextProvider
      authStrings={authStrings}
      shopFormStrings={shopFormStrings}
    >
      <ShopFormUploadContextProvider>
        <div className={cx('', className)}>
          <ShopFormProgressIndicator />

          <div className="flex items-start overflow-hidden md:mt-10 py-10">
            <div style={stepStyle} className={getStepClassnames(1)}>
              <ShopFormStep1 />
            </div>

            <div style={stepStyle} className={getStepClassnames(2)}>
              <ShopFormStep2
                showSkipButton={
                  shopState.type === SanityPrintShopType.YEAR &&
                  mode === ShopFormMode.CREATE
                }
              />
            </div>

            <div style={stepStyle} className={getStepClassnames(3)}>
              <ShopFormStep3 isStepSeen={isStep3Seen} />
            </div>
          </div>
        </div>
      </ShopFormUploadContextProvider>
    </ShopFormStringsContextProvider>
  )
}

export default ShopForm
