import { SanityCollectionCarousel } from '@data/sanity/queries/types/blocks'

import Carousel from '@components/carousel'
import ProductCard from '@blocks/shop/product-card'

type CollectionCarouselProps = SanityCollectionCarousel

const CollectionCarousel = ({
  collection: { products },
}: CollectionCarouselProps) => {
  const options = {
    selector: '.collection-slide',
  }

  return (
    <Carousel options={options}>
      {products.map((product, index) => (
        <div className="collection-slide" key={`${index}-${product.id}`}>
          <ProductCard
            product={product}
            hasVisuals={!!product.photos.main || !!product.photos.listing}
            showGallery={product.useGallery === 'true' && !!product.photos.main}
            showThumbnails={
              product.useGallery === 'false' && !!product.photos.listing
            }
            showOption={!!product.surfaceOption}
            showPrice
          />
        </div>
      ))}
    </Carousel>
  )
}

export default CollectionCarousel
