import {
  SanityProductBundleSlotProduct,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { hasObject } from '@lib/helpers'
import { getOptionLabel } from '@lib/product'

import ProductOption from '@blocks/product/product-option'

interface BundleSlotOptionsProps {
  product: SanityProductBundleSlotProduct
  variant: SanityProductVariantFragment | null
  availableOptions: Record<string, string[]>
  optionNames: string[]
  onChange(variant: SanityProductVariantFragment): void
}

const BundleSlotOptions = ({
  product,
  variant,
  optionNames,
  availableOptions,
  onChange,
}: BundleSlotOptionsProps) => {
  return (
    <div>
      {product.options.map((option, index) => {
        const filteredValues = [
          ...option.values.filter((value) =>
            availableOptions[option.name]?.some(
              (optionValue) => optionValue === value
            )
          ),
        ]

        if (
          filteredValues.length === 0 ||
          !variant ||
          !optionNames.includes(option.name)
        ) {
          return null
        }

        const filteredOption = {
          ...option,
          values: filteredValues,
        }

        // Handle changes in option value - get new selected product variant
        const handleChange = (newValue: string) => {
          if (!variant) {
            return
          }

          const newOptions = variant.options.map((variantOption) =>
            variantOption.name === option.name
              ? {
                  ...variantOption,
                  value: newValue,
                }
              : variantOption
          )

          // Find variant that matches all new options
          const newVariant = product.variants?.find(({ options }) =>
            options.every((variantOption) =>
              hasObject(newOptions, variantOption)
            )
          )

          if (newVariant) {
            onChange(newVariant)
          }
        }

        // Get selected value for current option
        const value =
          variant.options.find(
            (variantOption) => variantOption.name === option.name
          )?.value ?? ''

        return (
          <ProductOption
            key={option.name}
            label={getOptionLabel(product.optionNames ?? [], option)}
            option={filteredOption}
            optionSettings={product.optionSettings ?? []}
            product={product}
            value={value}
            onChange={handleChange}
            strictMatch
          />
        )
      })}
    </div>
  )
}

export default BundleSlotOptions
