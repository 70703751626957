import { useContext, useEffect, useMemo, useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import { HasAccountProductListStrings } from '@data/sanity/queries/types/strings'
import { useUser } from '@lib/auth'
import { LanguageContext } from '@lib/language'
import { getUserDigitalProducts } from '@lib/product'
import { getPageUrl, PageType } from '@lib/routes'
import { serializers } from '@lib/serializers'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'
import { UserProduct, USER_LIST_PAGE_LIMIT } from '@lib/user'

import Button, { ButtonSize, ButtonVariant } from '@components/buttons/button'

interface AccountProductListProps extends HasAccountProductListStrings {
  className?: string
}

/**
 * User product list hook.
 */
const useUserProductList = () => {
  const { locale } = useContext(LanguageContext)
  const { productCatalogue } = useContext(ShopContext)
  const { user } = useUser()
  const [products, setProducts] = useState<UserProduct[]>([])
  const [isLoadingProducts, setIsLoadingProducts] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const clearProducts = () => {
    setProducts([])
    setIsInitialized(false)
  }

  // Initialize product list
  useEffect(() => {
    const userToken = user?.token
    const userProductIds = user?.ownedProducts?.map(({ id }) => id)

    if (!isInitialized && userToken && userProductIds) {
      const userSubscriptionProductIds =
        user?.activeSubscriptionProductIds ?? []

      const loadDigitalProducts = async () => {
        setIsInitialized(true)

        // Get all digital products owned by the user (including products from subscriptions)
        const userDigitalProducts = getUserDigitalProducts(
          productCatalogue,
          userProductIds,
          userSubscriptionProductIds
        )

        setProducts(userDigitalProducts)

        setIsLoadingProducts(false)
      }
      loadDigitalProducts()
    }

    if (isInitialized && !userToken) {
      clearProducts()
    }
  }, [isInitialized, locale, productCatalogue, user])

  return [products, isLoadingProducts] as const
}

const AccountProductList = ({
  accountStrings,
  className,
}: AccountProductListProps) => {
  const strings = useContext(StringsContext)
  const [products, isLoading] = useUserProductList()

  const [pageIndex, setPageIndex] = useState(1)

  const productPage = useMemo(
    () => products.slice(0, pageIndex * USER_LIST_PAGE_LIMIT),
    [pageIndex, products]
  )
  const hasMore = useMemo(
    () => pageIndex * USER_LIST_PAGE_LIMIT < products.length,
    [pageIndex, products]
  )

  return (
    <div className={className}>
      <h4 className="font-semibold">
        {accountStrings.accountProductListHeading}
      </h4>

      {productPage.length === 0 && !isLoading && (
        <BlockContent
          renderContainerOnSingleChild
          className="rc mt-3"
          blocks={accountStrings.accountProductListEmpty}
          serializers={serializers}
        />
      )}

      {productPage.length > 0 && (
        <div className="mt-4">
          <div className="justify-evenly py-4 px-5 text-sm hidden sm:flex">
            <div className="w-full">
              {accountStrings.accountProductListProduct}
            </div>
          </div>

          {productPage.map((product) => (
            <div
              key={product.id}
              className="flex flex-col sm:flex-row py-5 px-5 border-t border-gray border-opacity-50 text-sm bg-gray-light bg-opacity-30 hover:bg-opacity-50"
            >
              <div className="mb-4 sm:mb-0 sm:w-[80%] sm:pr-4">
                {product.title}
              </div>
              <div className="sm:w-[20%] text-right text-pageText">
                <a href={getPageUrl(PageType.PRODUCT, product.slug)}>
                  {accountStrings.accountProductListViewProduct}
                </a>
              </div>
            </div>
          ))}

          {hasMore && (
            <div className="mt-6 text-center">
              <Button
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
                disabled={isLoading}
                className="min-w-[140px]"
                onClick={() => setPageIndex((index) => index + 1)}
              >
                {isLoading ? strings.buttonLoading : strings.buttonLoadMore}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AccountProductList
