import cx from 'classnames'
import IntlTelInput, { CountryData } from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'

interface TelInputFieldProps {
  id: string
  errorMessage?: string
  label?: string
  borderBottom?: boolean
  isInvertedColors?: boolean
  onChange: (value: string) => void
  placeholder?: string
  className?: string
}

const TelInputField = ({
  id,
  placeholder,
  className,
  label,
  errorMessage,
  borderBottom,
  isInvertedColors,
  onChange,
}: TelInputFieldProps) => {
  const handleChange = (
    isValid: boolean,
    value: string,
    countryData: CountryData,
    fullNumber: string
  ) => {
    onChange(fullNumber)
  }

  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left')}>
        {label && (
          <label htmlFor={id} className="text-xs mb-2">
            {label}
          </label>
        )}
        <div
          className={cx('flex justify-between', {
            'border-error': errorMessage,
            'border-input-border': !errorMessage && !isInvertedColors,
            'border-input-inverted-border': !errorMessage && isInvertedColors,
            'border-b': borderBottom,
            border: !borderBottom,
          })}
        >
          <IntlTelInput
            preferredCountries={['dk']}
            containerClassName="intl-tel-input w-full"
            inputClassName={cx(
              'relative appearance-none w-full h-full py-2 px-4',
              'text-base leading-none',
              {
                'bg-input-bg text-input-text': !isInvertedColors,
                'bg-input-inverted-bg text-input-inverted-text':
                  isInvertedColors,
              }
            )}
            format
            fieldId={id}
            placeholder={placeholder}
            onPhoneNumberChange={handleChange}
          />
        </div>
        {errorMessage && (
          <span role="alert" className="mt-2 text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default TelInputField
