import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext, useEffect, useMemo, useState } from 'react'

import { SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  SanityProductBundle,
  SanityProductBundleGallery,
  SanityProductBundleSlot,
  SanityProductBundleSlotProduct,
  SanityProductBundleVariantMap,
  SanityProductListingPhoto,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { SanityDiscountType } from '@data/sanity/queries/types/site'

import { fadeAnimation } from '@lib/animate'
import { CartContext, VariantLineItem } from '@lib/cart'
import {
  DiscountContext,
  DiscountItem,
  DiscountsByType,
  getDiscountItems,
} from '@lib/discount'
import { hasObject, isEqual, parseJson } from '@lib/helpers'
import { sanityProductIdToShopifyId } from '@lib/product'

import BundleAddToCartButton from '@blocks/product-bundle/add-to-cart-button'
import BundlePrice, { BundlePriceDiscount } from '@blocks/product-bundle/price'
import BundleSlotHeader from '@blocks/product-bundle/slot-header'
import BundleSlotOptions from '@blocks/product-bundle/slot-options'
import BundleSlotSelection from '@blocks/product-bundle/slot-selection'
import PhotoCarousel from '@components/photo-carousel'
import Photo from '@components/photo'

interface ProductBundleFormProps {
  productBundle: SanityProductBundle
  showGallery?: boolean
}

type BundleProduct = SanityProductBundleSlotProduct | null

type BundleVariant = SanityProductVariantFragment | null

type BundleVariantPhoto = SanityImageFragment | null

type AvailableOption = Record<string, string[]>

interface BundleFormState {
  selectedProducts: BundleProduct[]
  selectedVariants: BundleVariant[]
  selectedVariantPhotos: BundleVariantPhoto[]
  availableProductIds: number[][]
  availableVariantIds: number[][]
  availableOptions: AvailableOption[]
  galleryPhotos: SanityImageFragment[]
}

/**
 * Checks that all selected products are available.
 */
const isProductSelectionValid = (state: BundleFormState) =>
  state.selectedProducts.every((selectedProduct, slotIndex) => {
    const availableProductIds = state.availableProductIds[slotIndex]

    if (!selectedProduct || !availableProductIds) {
      return false
    }

    return availableProductIds.includes(selectedProduct.id)
  })

/**
 * Checks that all selected variants are available.
 */
const isVariantSelectionValid = (state: BundleFormState) =>
  state.selectedVariants.every((selectedVariant, slotIndex) => {
    const availableVariantIds = state.availableVariantIds[slotIndex]

    if (!selectedVariant || !availableVariantIds) {
      return false
    }

    return availableVariantIds.includes(selectedVariant.id)
  })

/**
 * Gets selected products from available product IDs.
 */
const getSeletedProducts = (
  slots: SanityProductBundleSlot[],
  state: BundleFormState
): BundleProduct[] =>
  slots.map((slot, slotIndex) => {
    const selectedProduct = state.selectedProducts[slotIndex]
    const availableProductIds = state.availableProductIds[slotIndex]

    // Check if selected product is available
    if (selectedProduct && availableProductIds.includes(selectedProduct.id)) {
      return selectedProduct
    }

    // Return first available product for slot
    return (
      slot.products.find(({ id }) => availableProductIds.includes(id)) ?? null
    )
  })

/**
 * Gets selected variants from available variant IDs and options.
 */
const getSeletedVariants = (
  slots: SanityProductBundleSlot[],
  state: BundleFormState
): BundleVariant[] =>
  slots.map((_, slotIndex) => {
    const selectedVariant = state.selectedVariants[slotIndex]
    const availableVariantIds = state.availableVariantIds[slotIndex] ?? []

    // Check if selected variant is available
    if (selectedVariant && availableVariantIds.includes(selectedVariant.id)) {
      return selectedVariant
    }

    const selectedProduct = state.selectedProducts[slotIndex]

    // TODO: Convert current selection to new product options, if they match.

    // Return first available variant for selected product
    return (
      selectedProduct?.variants?.find(({ id }) =>
        availableVariantIds.includes(id)
      ) ?? null
    )
  })

/**
 * Filters variant map by product IDs.
 */
const filterByProducts = (
  shopifyProductIds: (number | null)[],
  { products }: SanityProductBundleVariantMap
) =>
  shopifyProductIds.length === 0 ||
  shopifyProductIds.every((shopifyProductId) =>
    products.some(
      ({ id }) => sanityProductIdToShopifyId(id) === shopifyProductId
    )
  )

/**
 * Filters variant map by product and variant IDs.
 */
const filterByProductsAndVariants = (
  shopifyProductIds: (number | null)[],
  shopifyVariantIds: (number | null)[],
  { products }: SanityProductBundleVariantMap
) =>
  shopifyProductIds.length === 0 ||
  shopifyProductIds.every((shopifyProductId, slotIndex) => {
    const shopifyVariantId = shopifyVariantIds[slotIndex]

    return products.some(({ id, variantIds }) => {
      const hasProduct = shopifyProductId === sanityProductIdToShopifyId(id)
      const hasVariant =
        !shopifyVariantId ||
        variantIds.some(
          (variantId) =>
            shopifyVariantId === sanityProductIdToShopifyId(variantId)
        )

      return hasProduct && hasVariant
    })
  })

/**
 * Gets all available products based on selected products.
 */
const getAvailableProductIds = (
  slots: SanityProductBundleSlot[],
  variantMap: SanityProductBundleVariantMap[],
  state: BundleFormState
): number[][] =>
  slots.map((slot, slotIndex) => {
    // Get product IDs from previous slots
    const selectedProductIds = state.selectedProducts
      .map((product) => product?.id ?? null)
      .slice(0, slotIndex)

    // Filter variant map entries by selected product IDs
    const filteredVariantMap = variantMap.filter((variantMapItem) =>
      filterByProducts(selectedProductIds, variantMapItem)
    )

    // Filter slot products by filtered variant map
    return slot.products
      .map(({ id }) => id)
      .filter((id) =>
        filteredVariantMap.some(({ products }) =>
          products.some(
            (product) => id === sanityProductIdToShopifyId(product.id)
          )
        )
      )
  })

/**
 * Gets available variants for each slot based on selected products and variants.
 */
const getAvailableVariantIds = (
  slots: SanityProductBundleSlot[],
  variantMap: SanityProductBundleVariantMap[],
  state: BundleFormState
): number[][] =>
  slots.map((_, slotIndex) => {
    const selectedProduct = state.selectedProducts[slotIndex]

    if (!selectedProduct) {
      return []
    }

    // Get product and variant IDs from previous slots
    const selectedProductIds = state.selectedProducts
      .map((product) => product?.id ?? null)
      .slice(0, slotIndex)
    const selectedVariantIds = state.selectedVariants
      .map((variant) => variant?.id ?? null)
      .slice(0, slotIndex)

    // Filter variant map entries by selected product and variant IDs
    const filteredVariantMap = variantMap.filter((variantMapItem) =>
      filterByProductsAndVariants(
        selectedProductIds,
        selectedVariantIds,
        variantMapItem
      )
    )

    // Map variant map items to variant IDs for slot
    return filteredVariantMap
      .map(({ products }: SanityProductBundleVariantMap) => {
        const product = products.find(
          ({ id }) => sanityProductIdToShopifyId(id) === selectedProduct.id
        )

        return product
          ? (product.variantIds
              .map((id) => sanityProductIdToShopifyId(id))
              .filter(Boolean) as number[])
          : []
      })
      .flat(1)
  })

/**
 * Finds variant from slot data by ID.
 */
const getVariantFromSlot = (slot: SanityProductBundleSlot, variantId: number) =>
  slot.products
    .map((product) => product.variants?.find(({ id }) => variantId === id))
    .filter(Boolean)[0] ?? null

/**
 * Gets available options from available variant IDs.
 */
const getAvailableOptions = (
  slots: SanityProductBundleSlot[],
  state: BundleFormState
) => {
  const newAvailableOptions: AvailableOption[] = slots.map(() => ({}))

  state.availableVariantIds.forEach((availableVariantIds, slotIndex) => {
    const selectedVariant = state.selectedVariants[slotIndex]
    const selectedOptionValues =
      selectedVariant?.options
        ?.map(({ name, value }) => `${name}|${value}`)
        ?.filter(Boolean) ?? []
    const selectedOptionValueCount = selectedOptionValues.length

    availableVariantIds.forEach((availableVariantId) => {
      const variant = getVariantFromSlot(slots[slotIndex], availableVariantId)
      const options = variant?.options ?? []

      // Check that all (or all except 1) of the variant's options are selected
      // Does not filter selected varaint and variants that can be selected by changing no more than 1 option
      if (selectedOptionValueCount > 0) {
        const optionValues = options
          .map(({ name, value }) => `${name}|${value}`)
          .filter(Boolean)
        const matchingOptionValueCount = optionValues.filter((optionValue) =>
          selectedOptionValues.includes(optionValue)
        ).length

        if (matchingOptionValueCount < selectedOptionValueCount - 1) {
          return
        }
      }

      // Add all variant options to available options
      options.forEach(({ name, value }) => {
        const availableOption = newAvailableOptions[slotIndex]
        const availableOptionValues = availableOption[name] ?? []

        if (!availableOptionValues.includes(value)) {
          newAvailableOptions[slotIndex] = {
            ...availableOption,
            [name]: [...availableOptionValues, value],
          }
        }
      })
    })
  })

  return newAvailableOptions
}

/**
 * Gets product listing photo for selected variant.
 */
const getProductListingPhoto = (
  photosets: SanityProductListingPhoto[],
  variant: SanityProductVariantFragment
): SanityImageFragment | undefined => {
  const variantPhotoset = photosets.find(({ forOption }) => {
    const option = forOption
      ? { name: forOption.split(':')[0], value: forOption.split(':')[1] }
      : {}

    return option.value && hasObject(variant.options, option)
  })

  if (variantPhotoset?.default) {
    return variantPhotoset?.default
  }

  return photosets.find(({ forOption }) => !forOption)?.default
}

/**
 * Gets the listing photos for the selected variants.
 */
const getVariantListingPhotos = (state: BundleFormState) =>
  state.selectedVariants.map((selectedVariant, slotIndex) => {
    const selectedProduct = state.selectedProducts[slotIndex]
    const listingPhotos = selectedProduct?.photos?.listing ?? []

    if (!selectedVariant) {
      return null
    }

    return getProductListingPhoto(listingPhotos, selectedVariant) ?? null
  })

/**
 * Gets bundle gallery photos based on selected variants.
 */
const getGalleryPhotos = (
  slots: SanityProductBundleSlot[],
  bundleGallery: SanityProductBundleGallery[],
  state: BundleFormState
) => {
  const selectedVariantIds = state.selectedVariants.map(
    (selectedVariant) => selectedVariant?.id
  )

  return (
    bundleGallery.find(({ variantCombination }) => {
      const combinedItem = parseJson(variantCombination)
      const variantIds = combinedItem?.ids
        ? (combinedItem.ids as string).split(',').map((id) => Number(id))
        : []

      // Match variant count to slot count and every variant in every slot
      return (
        variantIds.length === slots.length &&
        variantIds.every(
          (variantId) => variantId && selectedVariantIds.includes(variantId)
        )
      )
    })?.photos ?? []
  )
}

/**
 * Gets line items from selected variants.
 */
const getVariantLineItems = (state: BundleFormState) =>
  (
    state.selectedVariants.filter(
      (selectedVariant) => selectedVariant
    ) as SanityProductVariantFragment[]
  ).map(({ id }) => ({ id, quantity: 1 }))

/**
 * Gets total price for selected variants.
 */
const getPriceAmount = (state: BundleFormState) =>
  state.selectedVariants.reduce(
    (total, selectedVariant) => total + (selectedVariant?.price ?? 0),
    0
  )

/**
 * Gets total discount for selected variants.
 */
const getBundlePriceDiscount = (
  discounts: DiscountsByType,
  state: BundleFormState
): BundlePriceDiscount => {
  const variantLineItems: VariantLineItem[] = (
    state.selectedVariants.filter(
      (selectedVariant) => selectedVariant
    ) as SanityProductVariantFragment[]
  ).map(({ id, price }) => ({ id, price, quantity: 1 }))
  const discountItems = getDiscountItems(discounts, variantLineItems)

  // Get the first bundle discount
  const discountItem: DiscountItem | undefined = discountItems.filter(
    ({ type }) => type === SanityDiscountType.BUNDLE
  )[0]

  return {
    amount: discountItem?.amount ?? 0,
    percent: discountItem?.discountValuePercent ?? 0,
  }
}

/**
 * Gets next bundle form state from existing state.
 */
const getNextState = (
  slots: SanityProductBundleSlot[],
  productBundle: SanityProductBundle,
  state: BundleFormState
): BundleFormState => {
  // Update selected products
  if (!isProductSelectionValid(state)) {
    const selectedProducts = getSeletedProducts(slots, state)

    if (!isEqual(state.selectedProducts, selectedProducts)) {
      return { ...state, selectedProducts }
    }
  }

  // Update available product IDs
  const availableProductIds = getAvailableProductIds(
    slots,
    productBundle.variantMap,
    state
  )

  if (!isEqual(state.availableProductIds, availableProductIds)) {
    return { ...state, availableProductIds }
  }

  // Update selected variants
  if (!isVariantSelectionValid(state)) {
    const selectedVariants = getSeletedVariants(slots, state)

    if (!isEqual(state.selectedVariants, selectedVariants)) {
      return { ...state, selectedVariants }
    }
  }

  // Update available variant IDs
  const availableVariantIds = getAvailableVariantIds(
    slots,
    productBundle.variantMap,
    state
  )

  if (!isEqual(state.availableVariantIds, availableVariantIds)) {
    return { ...state, availableVariantIds }
  }

  // Update variant options
  const availableOptions = getAvailableOptions(slots, state)

  if (!isEqual(state.availableOptions, availableOptions)) {
    return { ...state, availableOptions }
  }

  // Update variant photos
  const selectedVariantPhotos = getVariantListingPhotos(state)

  if (!isEqual(state.selectedVariantPhotos, selectedVariantPhotos)) {
    return { ...state, selectedVariantPhotos }
  }

  // Update bundle gallery photos
  const galleryPhotos = getGalleryPhotos(
    slots,
    productBundle.gallery ?? [],
    state
  )

  if (!isEqual(state.galleryPhotos, galleryPhotos)) {
    return { ...state, galleryPhotos }
  }

  return { ...state }
}

/**
 * Gets final bundle form state from existing state.
 */
const getFinalState = (
  slots: SanityProductBundleSlot[],
  productBundle: SanityProductBundle,
  state: BundleFormState,
  limit = 100
): BundleFormState => {
  let previousState = { ...state }
  let nextState = getNextState(slots, productBundle, state)
  let count = 1

  // Continue until the bundle form state is not changing any more
  while (!isEqual(previousState, nextState)) {
    previousState = { ...nextState }
    nextState = getNextState(slots, productBundle, nextState)

    // Limit the total count
    count += 1

    if (count >= limit) {
      break
    }
  }

  return nextState
}

/**
 * Updates slots with correct product order.
 */
const getSlotsWithSortedProducts = (
  slots: SanityProductBundleSlot[]
): SanityProductBundleSlot[] =>
  slots.map((slot) => {
    const productIds = slot.selectedOptionNames.map(({ productId }) =>
      sanityProductIdToShopifyId(productId)
    )
    const products = [
      ...slot.products.sort(
        (product1, product2) =>
          productIds.indexOf(product1.id) - productIds.indexOf(product2.id)
      ),
    ]

    return { ...slot, products }
  })

/**
 * Gets option names by product ID.
 */
const getOptionNamesByProduct = (
  slot: SanityProductBundleSlot,
  productId?: number
): string[] =>
  slot.selectedOptionNames.find(
    (selectedOptionName) =>
      sanityProductIdToShopifyId(selectedOptionName.productId) === productId
  )?.list ?? []

/**
 * Sets a product in bundle form state.
 */
const setStateProduct = (
  state: BundleFormState,
  slotIndex: number,
  product: BundleProduct
) => {
  const selectedProducts = [...state.selectedProducts]
  selectedProducts[slotIndex] = product

  return { ...state, selectedProducts }
}

/**
 * Sets a variant in bundle form state.
 */
const setStateVariant = (
  state: BundleFormState,
  slotIndex: number,
  variant: BundleVariant
) => {
  const selectedVariants = [...state.selectedVariants]
  selectedVariants[slotIndex] = variant

  return { ...state, selectedVariants }
}

/**
 * Gets an empty product bundle state.
 */
const getEmptyState = (slots: SanityProductBundleSlot[]): BundleFormState => ({
  selectedProducts: slots.map(() => null),
  selectedVariants: slots.map(() => null),
  selectedVariantPhotos: slots.map(() => null),
  availableProductIds: slots.map(() => []),
  availableVariantIds: slots.map(() => []),
  availableOptions: slots.map(() => ({})),
  galleryPhotos: [],
})

const emptyDiscount: BundlePriceDiscount = {
  amount: 0,
  percent: 0,
}

/**
 * The product bundle form component.
 */
const ProductBundleForm = ({
  productBundle,
  showGallery,
}: ProductBundleFormProps) => {
  const { discounts } = useContext(DiscountContext)
  const { addItemsToCart } = useContext(CartContext)

  const [isAddToCartError, setIsAddToCartError] = useState(false)
  const [price, setPrice] = useState(0)
  const [discount, setDiscount] = useState<BundlePriceDiscount>(emptyDiscount)
  const [state, setState] = useState(() => getEmptyState(productBundle.slots))
  const [isStateFinal, setIsStateFinal] = useState(false)

  const slots = useMemo(
    () => getSlotsWithSortedProducts(productBundle.slots),
    [productBundle.slots]
  )

  // Update state
  useEffect(() => {
    if (isStateFinal) {
      return
    }

    setIsStateFinal(true)
    setState((currentState) =>
      getFinalState(slots, productBundle, currentState)
    )
  }, [isStateFinal, productBundle, slots])

  // Update price
  useEffect(() => {
    const newPrice = getPriceAmount(state)

    setPrice(newPrice)
  }, [state])

  // Update discount
  useEffect(() => {
    if (!discounts) {
      return
    }

    const newDiscount = getBundlePriceDiscount(discounts, state)

    setDiscount(newDiscount)
  }, [discounts, state])

  const handleSlotClick = (
    slotIndex: number,
    product: SanityProductBundleSlotProduct
  ) => {
    setState((currentState) =>
      setStateProduct(currentState, slotIndex, product)
    )
    setIsStateFinal(false)
  }

  const handleOptionClick = (
    slotIndex: number,
    variant: SanityProductVariantFragment
  ) => {
    setState((currentState) =>
      setStateVariant(currentState, slotIndex, variant)
    )
    setIsStateFinal(false)
  }

  const handleAddToCart = async () => {
    setIsAddToCartError(false)

    const isSuccessful = await addItemsToCart(async () =>
      getVariantLineItems(state)
    )

    setIsAddToCartError(!isSuccessful)
  }

  // The form content
  let content = (
    <div>
      {slots.map((slot, slotIndex) => {
        const selectedProduct = state.selectedProducts[slotIndex]
        const selectedVariant = state.selectedVariants[slotIndex]
        const selectedVariantPhoto = state.selectedVariantPhotos[slotIndex]
        const availableProductIds = state.availableProductIds[slotIndex]
        const availableOptions = state.availableOptions[slotIndex]

        if (!selectedProduct) {
          return null
        }

        const selectedOptionNames = getOptionNamesByProduct(
          slot,
          selectedProduct?.id
        )

        return (
          <div
            key={slot.name}
            className={cx('mb-10', { 'border-t pt-7': slotIndex > 0 })}
          >
            <BundleSlotHeader
              slotTitle={`${slotIndex + 1}. ${slot.name}`}
              selectedVariantTitle={selectedVariant?.title}
              selectedVariantPhoto={selectedVariantPhoto}
            />
            <div>
              {slot.showSelection && (
                <BundleSlotSelection
                  label={slot.label}
                  products={slot.products.filter(({ id }) =>
                    availableProductIds.includes(id)
                  )}
                  selectedProductId={selectedProduct.id}
                  onClick={(product) => handleSlotClick(slotIndex, product)}
                />
              )}

              <div>
                <BundleSlotOptions
                  product={selectedProduct}
                  variant={selectedVariant}
                  optionNames={selectedOptionNames}
                  availableOptions={availableOptions}
                  onChange={(variant) => handleOptionClick(slotIndex, variant)}
                />
              </div>
            </div>
          </div>
        )
      })}

      <BundlePrice price={price} discount={discount} />

      <BundleAddToCartButton
        onClick={handleAddToCart}
        isAddToCartError={isAddToCartError}
      />
    </div>
  )

  // The form with gallery content
  if (showGallery) {
    // Update ID to trigger transition animation
    const photosetId = state.galleryPhotos.map(({ id }) => id).join('')

    content = (
      <section className="container xs:py-8 md:py-24">
        <div className="relative grid grid-cols-1 md:grid-cols-12">
          <div className="relative mb-12 md:mb-0 md:col-span-6 bg-pageBG">
            <AnimatePresence mode="wait">
              <motion.div
                key={photosetId}
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnimation}
              >
                <PhotoCarousel
                  id={photosetId}
                  slideCount={state.galleryPhotos.length}
                  hasArrows
                  hasCounter
                  hasDrag
                  cornerControls={true}
                >
                  {state.galleryPhotos.map((photo) => (
                    <div
                      key={photo.id}
                      className="carousel-slide relative flex flex-grow-0 flex-shrink-0 w-full min-h-full overflow-hidden justify-center"
                    >
                      <Photo image={photo} />
                    </div>
                  ))}
                </PhotoCarousel>
              </motion.div>
            </AnimatePresence>
          </div>

          <div className="container bg-pageBG flex flex-col md:pl-14 lg:pl-20 md:col-span-6">
            <div className="max-w-2xl mx-auto">{content}</div>
          </div>
        </div>
      </section>
    )
  }

  return content
}

export default ProductBundleForm
