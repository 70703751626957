import { useCallback, useContext, useState, ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import cx from 'classnames'

import { useDebounceCallback } from '@lib/helpers'
import { LanguageContext, Locale } from '@lib/language'
import { ShopPrint, updatePrintTitle } from '@lib/print'
import { ShopFormContext, ShopActionType } from '@lib/shop'
import { ShopFormStringsContext } from '@lib/strings'

import Icon from '@components/icon'
import Photo from '@components/photo'
import InputField from '@components/input-field'

export enum PrintItemType {
  EDIT = 'edit',
  EDIT_TITLE_AND_REMOVE = 'editTitleAndRemove',
}

interface PrintItemProps {
  print: ShopPrint
  type: PrintItemType
  isDisabled?: boolean
  onEditClick?: (printId: string) => void
  onRemoveClick?: (printId: string) => Promise<void>
}

const ShopFormPrintItem = ({
  print,
  type,
  isDisabled,
  onEditClick,
  onRemoveClick,
}: PrintItemProps) => {
  const { locale } = useContext(LanguageContext)
  const { shopState, dispatchShopState } = useContext(ShopFormContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)
  const { register } = useForm()

  const [title, setTitle] = useState<string>(print.title ?? '')

  const debouncedUpdatePrintTitle = useDebounceCallback(
    (locale: Locale, shopId: string, printId: string, title: string) => {
      updatePrintTitle(locale, shopId, printId, title)
    },
    600
  )

  const handleTitleChange = useCallback(
    async (newTitle: string) => {
      if (newTitle === title) {
        return
      }

      setTitle(newTitle)
      dispatchShopState({
        action: ShopActionType.EDIT_PRINT,
        id: print.id,
        title: newTitle,
      })

      if (!shopState.shopId) {
        return
      }

      debouncedUpdatePrintTitle(locale, shopState.shopId, print.id, newTitle)
    },
    [
      debouncedUpdatePrintTitle,
      dispatchShopState,
      locale,
      print.id,
      shopState.shopId,
      title,
    ]
  )

  const titleRegister = register(`print-title-${print.id}`, {
    onChange: ({ target }: ChangeEvent<HTMLSelectElement>) =>
      handleTitleChange(target.value),
    onBlur: ({ target }: ChangeEvent<HTMLSelectElement>) =>
      handleTitleChange(target.value),
  })

  return (
    <div className={cx('mb-10', { 'opacity-50': isDisabled })}>
      <h4 className="font-semibold mb-4">
        {print.title ? print.title : print.filename}
      </h4>
      <div className="p-4 md:px-8 rounded-md border border-gray-light bg-gray-light bg-opacity-30 mb-3 flex flex-col sm:flex-row justify-between">
        <div className="flex items-center justify-center flex-col sm:flex-row sm:pr-6">
          <div className="sm:mr-6 max-w-[100px] overflow-hidden flex items-center">
            <Photo image={print.image} />
          </div>
          <p className="text-base text-center sm:text-left my-3 sm:my-0">
            {print.filename}
          </p>
        </div>

        <div className="flex flex-col justify-center items-center">
          {type === PrintItemType.EDIT && (
            <button
              className="inline-flex items-center"
              onClick={() => {
                if (!isDisabled && onEditClick) {
                  onEditClick(print.id)
                }
              }}
            >
              <Icon
                className="mr-2 text-lg"
                name="Edit"
                id={`edit-print-${print.id}`}
              />
              <span className="">{shopFormStrings.shopFormPrintEditLink}</span>
            </button>
          )}
          {type === PrintItemType.EDIT_TITLE_AND_REMOVE && (
            <button
              className="inline-flex items-center text-left text-red"
              onClick={() => {
                if (!isDisabled && onRemoveClick) {
                  onRemoveClick(print.id)
                }
              }}
            >
              <Icon
                className="mr-2 text-lg"
                name="Trash"
                id={`remove-print-${print.id}`}
              />
              <span className="">
                {shopFormStrings.shopFormRemovePrintLink}
              </span>
            </button>
          )}
        </div>
      </div>

      {type === PrintItemType.EDIT_TITLE_AND_REMOVE && (
        <InputField
          type="text"
          inputMode="text"
          value={title}
          placeholder={shopFormStrings.shopFormPrintTitlePlaceholder?.replace(
            /{print_type}/gi,
            print.type
          )}
          label={shopFormStrings.shopFormPrintTitleLabel?.replace(
            /{print_type}/gi,
            print.type
          )}
          id={`print-title-${print.id}`}
          formRegister={titleRegister}
        />
      )}
    </div>
  )
}

export default ShopFormPrintItem
