import { useContext } from 'react'
import cx from 'classnames'

import { ShopFormContext } from '@lib/shop'
import { ShopFormStringsContext } from '@lib/strings'

interface StepIndiatorProps {
  isSelected: boolean
  title: string
  showLine?: boolean
  className?: string
}

/**
 * Step progress indicator item.
 */
const ShopFormStepIndicator = ({
  isSelected,
  title,
  showLine,
  className,
}: StepIndiatorProps) => (
  <div
    className={cx(
      'relative flex justify-center items-start px-[8px]',
      {
        'before:absolute before:top-[14px] before:left-[calc((-50%+2em)-8px)] before:right-[calc((50%+2em)-8px)] before:bg-yellow before:h-[2px]':
          showLine,
      },
      className
    )}
  >
    <div className="inline-flex flex-col items-center">
      <span
        className={cx('w-[30px] h-[30px] rounded-full mx-auto mb-2', {
          'bg-yellow': isSelected,
          'border-yellow border-[2px]': !isSelected,
        })}
      ></span>
      <span className="text-xl font-medium text-center">{title}</span>
    </div>
  </div>
)

const ShopFormProgressIndicator = () => {
  const { step } = useContext(ShopFormContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)

  return (
    <div className="flex flex-row mx-auto max-w-[600px]">
      <ShopFormStepIndicator
        isSelected={step >= 1}
        title={shopFormStrings.shopFormStep1Name}
        className="flex-1"
      />
      <ShopFormStepIndicator
        isSelected={step >= 2}
        title={shopFormStrings.shopFormStep2Name}
        showLine
        className="flex-1"
      />
      <ShopFormStepIndicator
        isSelected={step === 3}
        title={shopFormStrings.shopFormStep3Name}
        showLine
        className="flex-1"
      />
    </div>
  )
}

export default ShopFormProgressIndicator
