import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import BlockContent from '@sanity/block-content-to-react'

import { HasAccountYearShopListStrings } from '@data/sanity/queries/types/strings'
import {
  SanityPrintShopType,
  SanityUserShop,
} from '@data/sanity/queries/types/shop'
import { useUser } from '@lib/auth'
import { isEqual } from '@lib/helpers'
import { LanguageContext } from '@lib/language'
import { getPageUrl, PageType } from '@lib/routes'
import { SchoolContext } from '@lib/school'
import { serializers } from '@lib/serializers'
import { deactivatePrivateShop, ShopItem } from '@lib/shop'

import Button, {
  ButtonColor,
  ButtonIconAlignment,
  ButtonIconSize,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import SimpleLink from '@components/simple-link'

interface AccountYearShopListProps extends HasAccountYearShopListStrings {
  className?: string
}

const AccountYearShopList = ({
  accountStrings,
  className,
}: AccountYearShopListProps) => {
  const { locale } = useContext(LanguageContext)
  const { schools } = useContext(SchoolContext)
  const { user, mutateUser } = useUser()
  const router = useRouter()

  const [shops, setShops] = useState<SanityUserShop[]>([])
  const [deletedShopIds, setDeletedShopIds] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  // Wait for user to load
  useEffect(() => {
    if (user && isLoading) {
      setIsLoading(false)
    }
  }, [isLoading, user])

  // Load shops from user details
  useEffect(() => {
    if (!user?.shops || isLoading) {
      return
    }

    const newYearShops =
      user.shops?.filter((shop) => shop.type === SanityPrintShopType.YEAR) ?? []

    if (!isEqual(shops, newYearShops)) {
      setShops(newYearShops)
    }
  }, [isLoading, user?.shops, shops])

  // Update shop item list
  const shopItems = useMemo<ShopItem[]>(
    () =>
      shops.map((shop) => ({
        shopId: shop.shopId,
        id: shop.id,
        name: shop.name,
        schoolName: schools.find((school) => school.id === shop.schoolId)?.name,
        date: shop.date,
        isActive: !deletedShopIds.includes(shop.shopId),
      })),
    [deletedShopIds, schools, shops]
  )

  const handleEditClick = useCallback(
    (id: string) => router.push(getPageUrl(PageType.EDIT_YEAR_SHOP_PAGE, id)),
    [router]
  )

  const handleDeleteClick = useCallback(
    async (shopId: string) => {
      if (!user) {
        return
      }

      // Show confirmation
      const confirmResult = await confirm(
        accountStrings.accountShopListDeleteConfirmation
      )

      if (!confirmResult) {
        return
      }

      // Disable shop
      setDeletedShopIds((shopIds) => [...shopIds, shopId])

      const response = await deactivatePrivateShop(locale, shopId)

      if (!!response?.error) {
        // Enable shop
        setDeletedShopIds((currentDeletedShopIds) => [
          ...currentDeletedShopIds.filter(
            (currentDeletedShopId) => currentDeletedShopId !== shopId
          ),
        ])
        return
      }

      // Remove shop
      mutateUser({
        ...user,
        shops: user.shops?.filter((shop) => shop.shopId !== shopId),
      })
    },
    [accountStrings.accountShopListDeleteConfirmation, locale, mutateUser, user]
  )

  const dateFormat = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <div className={className}>
      <h4 className="font-semibold">
        {accountStrings.accountShopListYearListHeading}
      </h4>

      {shopItems.length === 0 && !isLoading && (
        <BlockContent
          renderContainerOnSingleChild
          className="rc mt-3"
          blocks={accountStrings.accountShopListYearListEmpty}
          serializers={serializers}
        />
      )}

      {shopItems.length > 0 && (
        <div className="mt-4">
          <div className="justify-evenly py-4 px-5 text-pageText text-sm hidden sm:flex">
            <div className="w-[30%] pr-3">
              {accountStrings.accountShopListName}
            </div>
            <div className="w-[30%] pr-3">
              {accountStrings.accountShopListSchool}
            </div>
            <div className="w-[20%] pr-3">
              {accountStrings.accountShopListDate}
            </div>
            <div className="w-[10%] pr-3"></div>
            <div className="w-[10%] pr-3"></div>
          </div>

          {shopItems.map((shopItem) => (
            <SimpleLink
              key={shopItem.id}
              className={cx(
                'flex flex-col sm:flex-row cursor-pointer py-5 px-5 border-t border-gray border-opacity-50 text-sm bg-gray-light bg-opacity-30 hover:bg-opacity-60',
                { 'opacity-50': !shopItem.isActive }
              )}
              href={getPageUrl(PageType.EDIT_YEAR_SHOP_PAGE, shopItem.id, {
                parameters: { overview: 'true' },
              })}
            >
              <div className="sm:w-[30%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountShopListName}
                </div>
                <div className="text-right sm:text-left sm:pr-3">
                  {shopItem.name}
                </div>
              </div>

              <div className="sm:w-[30%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountShopListSchool}
                </div>
                <div className="text-right sm:text-left sm:pr-3">
                  {shopItem.schoolName}
                </div>
              </div>

              <div className="sm:w-[20%] flex justify-between sm:block mb-3 sm:mb-0">
                <div className="text-pageText sm:hidden">
                  {accountStrings.accountShopListDate}
                </div>
                <div className="text-right sm:text-left sm:pr-3">
                  {dateFormat.format(new Date(shopItem.date))}
                </div>
              </div>

              <div className="sm:w-[10%] flex justify-between sm:block mb-3 sm:mb-0 text-right">
                <div className="text-pageText sm:hidden"></div>
                <button
                  className="text-left sm:pr-3"
                  disabled={!shopItem.isActive}
                  onClick={(event) => {
                    event.preventDefault()
                    handleEditClick(shopItem.id)
                  }}
                >
                  {accountStrings.accountShopListEdit}
                </button>
              </div>

              <div className="sm:w-[10%] flex justify-between sm:block mb-3 sm:mb-0 text-right">
                <div className="text-pageText sm:hidden"></div>
                <button
                  className="text-left sm:pr-3 text-red"
                  disabled={!shopItem.isActive}
                  onClick={(event) => {
                    event.preventDefault()
                    handleDeleteClick(shopItem.shopId)
                  }}
                >
                  {accountStrings.accountShopListDelete}
                </button>
              </div>
            </SimpleLink>
          ))}
        </div>
      )}

      <div className="mt-6 text-center">
        <NextLink href={getPageUrl(PageType.CREATE_YEAR_SHOP_PAGE)}>
          <Button
            className="btn"
            role="link"
            tabIndex={0}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            color={ButtonColor.DEFAULT}
            icon="Plus"
            iconSize={ButtonIconSize.NORMAL}
            iconAlignment={ButtonIconAlignment.LEFT}
            disabled={isLoading}
          >
            {accountStrings.accountShopListCreateYearShop}
          </Button>
        </NextLink>
      </div>
    </div>
  )
}

export default AccountYearShopList
