import { useContext } from 'react'

import {
  SanityBlogPostWithoutBody,
  SanityBlogPostOptions,
} from '@data/sanity/queries/types/blog'
import { getFormattedDate } from '@lib/helpers'
import { LanguageContext } from '@lib/language'
import { getPageUrl, PageType } from '@lib/routes'

import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

interface BlogPostCardProps {
  post: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

const BlogPostCard = ({ post, options }: BlogPostCardProps) => {
  const { locale } = useContext(LanguageContext)

  return (
    <SimpleLink
      href={getPageUrl(PageType.BLOG_POST, post.slug)}
      className="cursor-pointer"
    >
      {post.coverImage && options?.showCoverImage && (
        <div className="relative pt-[calc((9/16)*100%)] overflow-hidden mb-3">
          <Photo
            image={post.coverImage}
            layout="responsive"
            className="absolute inset-0"
          />
        </div>
      )}

      <h4 className="mb-1 font-sans font-semibold">{post.title}</h4>

      {options?.showExcerpt && (
        <p className="opacity-50 mb-4">{post.excerpt}</p>
      )}

      {options?.showCategories && (
        <div className="flex flex-wrap mb-4">
          {post.categories?.map((category) => (
            <span
              className="mr-2 text-[12px] opacity-50"
              key={`${post.slug}-category-${category.slug}`}
            >
              {category.name}
            </span>
          ))}
        </div>
      )}

      {(options?.showAuthorPhoto ||
        options?.showAuthorName ||
        options?.showDate) && (
        <div className="flex items-center">
          {options?.showAuthorPhoto && post.author?.photo && (
            <Photo
              image={post.author?.photo}
              objectFit="cover"
              forceWidth={40}
              forceHeight={40}
              className="rounded-full overflow-hidden w-[40px] h-[40px] mr-2"
            />
          )}
          <div>
            {options?.showAuthorName && (
              <p className="text-sm">{post.author?.name}</p>
            )}{' '}
            {options?.showDate && (
              <p className="text-sm opacity-50">
                {getFormattedDate(post.date, locale)}
              </p>
            )}
          </div>
        </div>
      )}
    </SimpleLink>
  )
}

export default BlogPostCard
